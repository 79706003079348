import React,{useEffect,useState} from 'react'
import axios from 'axios'
import SpinnerLoader from '../details/SpinnerLoader'
import {PageView, initGA} from '../../components/Tracking';
import ReactPixel from 'react-facebook-pixel';

function RedirectPage(props) {
    const [page, setPage] = useState(null)
    useEffect(() => {
        initGA()
        PageView()
        const advancedMatching = {};
        const options = {
            autoConfig: true, 
            debug: false,
        };
        ReactPixel.init('704276380318113', advancedMatching, options);
        ReactPixel.pageView();

        axios.get('/api/v1/redirectpage/'+props.match.params.code+'/').then((res)=>{
            window.location.href = res.data.redirecturl
        }).catch(error=>{
            window.location.href = "/404";
        })
    }, [])

    return(
        <div>
            <SpinnerLoader />
        </div>
)
    
}

export default RedirectPage;