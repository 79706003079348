import React, { Component } from 'react';
import './App.css';
import MetaHelmet from './components/items/MetaHelmet'
import Header from './components/homepage/Header'
import Footer from './components/homepage/Footer'
import FooterInfo from './components/details/FooterInfo';

class App extends Component {
  constructor(props){
    super(props);
  }
  componentDidMount(){  
    this.setState({...this.state , footer: this.props.footer},()=>{
    })
  }
  render() {
    return (
      <>
        <Header withMenu={this.props.withMenu}/>
        <div className="container-fluid contenitore" style={{ textAlign:"left",position:"relative"}}>
          {this.props.children}
          <FooterInfo />
        </div>
        {/* <Footer/> */}
        
        
      </>
    );
  }
}

export default App;