import React,{useState,useEffect} from 'react'
import axios from 'axios'
import MetaHelmet from '../items/MetaHelmet'
import Moments from '../items/Moments'
import SpinnerLoader from './SpinnerLoader'
import { Pinterest,Facebook } from 'react-sharingbuttons'
import 'react-sharingbuttons/dist/main.css'
import NewsLetterButton from './NewsLetterButton'
import {DiscussionEmbed} from "disqus-react"

let url = window.location.href;

function BlogPost(props) {
    const [post, setPost] = useState(undefined)
    const [disqusConfig, setDisqusConfig] = useState(undefined)
    const disqusShortname = "blackandwire" //found in your Disqus.com dashboard
    const initialConf = {
      url: window.location.href, //this.props.pageUrl
      identifier: "article-id", //this.props.uniqueId
      title: "Title of Your Article" //this.props.title
    }
    
    useEffect(() => {
        axios.get('/api/v1/blog/'+props.match.params.gurl).then((res)=>{
            // setDisquss()
            setPost(res.data)
            // setDisqusConfig({
            //     url: window.location.href,
            //     identifier: res.data.gurl,
            //     title: res.data.title
            //   })
        })
    }, [props.match.params.gurl])
    return(
        <div className={"blog"}>
            <div style={{margin:"0 auto",width:"100%",maxWidth:"1000px"}}> 
            {post!==undefined ? 
            <>
                <MetaHelmet title={post.gtitle} description={post.gdescription} url={url} image={post.file!==null ? post.file.split("?")[0] : undefined} />
                { post.file!==null ?
                 <div className="container header image-placement-behind d-none d-md-block" 
                 style={{ backgroundImage: "url(" + post.file.split("?")[0] + ")", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', padding:"0" }}> {/* .split("?")[0] */}

                </div>
                : <div className="container header image-placement-behind d-none d-md-block" 
                    style={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', padding:"0" }}> {/* .split("?")[0] */}
                    <div className="container titleblogwhite" style={{paddingTop:"10%"}}>
                    
                    </div>
                </div>}
                <div className="d-block d-md-none">
                    <div className="container titleblog">
                        { post.file!==null ?
                            <img src={post.file.split("?")[0]} style={{width:"100%"}} alt={post.alt}/>
                        :null}
                    </div>
                </div>
                <div className="container">
                    <div><h1>{post.title}</h1></div>
                    <div className="row" style={{textAlign:"left", color:"lightgrey",boderBottom:"1px solid #F5F5F5"}}>
                        <div className="col-6" style={{marginTop:"15px"}}>
                            Posted <b><Moments data={post.published_at}></Moments></b> ago
                        </div>
                        <div className="col-6" style={{textAlign:"right"}}>
                        { post.file!==null ?
                            <Pinterest url={url} mediaSrc={post.file.split("?")[0]} shareText={post.gdescription}/>
                            :
                            <Pinterest url={url}  shareText={post.gdescription}/>}
                        </div>
                    </div>
                    <div className="content descriptionblog">
                        <div dangerouslySetInnerHTML={{ __html: post.content}}/>
                    </div>
                    <div className="tags">
                        {post.tags.map((tag)=>{
                            return(
                                <span className="badge badge-secondary tagcolor" style={{marginRight:"5px",marginBottom:"5px"}}>{tag.name}</span>
                            )
                        })}
                    </div>
                    <div className="comment">
                    {disqusConfig ? 
                        <DiscussionEmbed
                        shortname={disqusShortname}
                        config={disqusConfig}
                    />
                    :null}
                    </div>
                </div>
            </>
            :
            <SpinnerLoader />
            }
            </div>
        </div>
    )
}

export default BlogPost;