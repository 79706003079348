import React,{useState,useEffect} from 'react';
import axios from 'axios'
import { PrecompilatoFattura, savePdf } from './homepage/PrecompilatoFattura';
import moment from 'moment'

function FattureTest(props) {
    const [logged, setLogged] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [numTentativi, setNumTentativi] = useState(3)
    const [messaggio, setMessaggio] = useState("")
    const [listOfPayment, setListOfPayment] = useState([])
    
    function scaricaFattura(payment){
        savePdf(<PrecompilatoFattura payment={payment} />, 'fattura_'+moment(payment.timestamp).format('DD-MM-YYYY')+'_'+payment.num_fattura+'.pdf');
    }
    
    useEffect(() => {
        if(logged){
            let data = {
                username:username,
                password:password
            }
            axios.put('/api/v1/corsi/listofmypayment/',data).then((res)=>{
                setListOfPayment(res.data.data)
            })
        }
    }, [logged])

    function checkPassword(e){
        e.preventDefault()
        setNumTentativi(numTentativi-1)
        if(numTentativi>0){
            if(username==="c2e19882-d785-4332-9911-423de7633a11" && password==="3af6a1a-883b-4ce2-b9fc-3d7b6b2ee0cc3"){
                setLogged(true)
            }else{
                setMessaggio("Errato, Riprova")
            }
        }
    }

    useEffect(() => {
        if(logged){
            axios.put()
        }
    }, [logged])

    return (
        <div>
            {logged ? 
                <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div class="table-responsive-sm">
                        <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Data</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Cognome</th>
                            <th scope="col">Riferimento</th>
                            <th scope="col">Metodo di pagamento</th>
                            <th scope="col">Pagato(€)</th>
                            <th scope="col">Codice fiscale/P. iva</th>
                            <th scope="col">Stato</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listOfPayment.map((payment,i)=>{
                                return(
                                <tr key={i}>
                                    <th scope="row">
                                        <a href={"/admin/corso/paymentusercorso/"+payment.id+"/change/"} target="_blank">
                                        {payment.id}</a></th>
                                    <td>{payment.timestamp.split("T")[0]}</td>
                                    <td>{payment.name}</td>
                                    <td>{payment.lastname}</td>
                                    <td>{payment.corso_name}</td>
                                    <td>{payment.method_payment}</td>
                                    <td>{payment.paid}</td>
                                    <td>{payment.vat_number}</td>

                                    <td>
                                        {payment.inattesa || !payment.num_fattura ? "IN ATTESA" : 
                                        <div>
                                            {Number(payment.paid)>77.47 ?
                                                !payment.codice_marca ? 
                                                    <span>CONFERMATO</span>
                                                    :
                                                    <span>CONFERMATO <a href="javascript:void()" onClick={()=>scaricaFattura(payment)}><i className="fas fa-file-download"></i></a></span>
                                            :
                                                <span>CONFERMATO <a href="javascript:void()" onClick={()=>scaricaFattura(payment)}><i className="fas fa-file-download"></i></a></span>
                                            }
                                        </div>
                                        }
                                    </td>
                                    
                                </tr>
                                )
                            })}
                        </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
            :<div>
                <form onSubmit={(e)=>checkPassword(e)}>
                    <input type="text" onChange={(e)=>setUsername(e.target.value)} /> <br/>
                    <input type="text" onChange={(e)=>setPassword(e.target.value)}/> <br/>
                    <button>login</button>
                </form>
                {messaggio}
            </div>}
        </div>
    );
}

export default FattureTest;