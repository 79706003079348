import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// // Demo styles, see 'Styles' section below for some notes on use.
// import 'react-accessible-accordion/dist/fancy-example.css';

export default function MyAccordion(props) {
    return (
        <Accordion>
            {props.items.map((item,i)=>{
                return(
                <AccordionItem key={i}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {item.title}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        {item.text}
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                )
            })}
        </Accordion>
    );
}
