import React from 'react';
import Loader from 'react-loader-spinner';

function SpinnerLoader(props) {
    return (
        <div>
            <Loader style={{paddingTop:"10%",textAlign:"center"}}
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    timeout={3000} //3 secs
                />
        </div>
    );
}

export function SpinnerLoaderCorso(props) {
    return (
        <div>
            <Loader style={{textAlign:"center",marginBottom:"10px"}}
                    type="ThreeDots"
                    color={props.color ? props.color : "#00BFFF"}
                    height={50}
                    width={50}
                    timeout={3000} //3 secs
                />
        </div>
    );
}


export default SpinnerLoader;