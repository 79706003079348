import React,{useState,useEffect} from 'react';
import axios from 'axios'
import ActiveHostedForm from './ActiveHostedForm';


function NewsLetterButton(props) {
    
    const [toggle, settoggle] = useState(false)
    const [toggleCheckEmail, settoggleCheckEmail] = useState(false)
    const [email, setEmail] = useState(undefined)
    const [name, setName] = useState(undefined)
    const [lastName, setLastName] = useState(undefined)
    const [error, setError] = useState(undefined)
    const [staticpage, setStaticpage] = useState(undefined)
    const [confermack, setConfermack] = useState(false)
    const [showPolicy, setShowPolicy] = useState(false)
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        if(!props.staticpage){
            axios.get("/api/v1/emailnewsletter/newsletter/null").then((res)=>{
                setStaticpage(res.data.data);
                setLoading(true)
            })
        }else{
            setStaticpage(props.staticpage)
            setLoading(true);
        }
        // initGA(props.trackinkid)
        // axios.get("/api/v1/emailnewsletter/newsletter/null").then((res)=>{
        //     setStaticpage(res.data.data);
        //     setLoading(true)
        // })
    }, [])

    function saveUser(e){
        e.preventDefault();
        let data={
            "name": name,
            "lastname": lastName,
            "email": email
        }

        axios.post('/api/v1/emailnewsletter/',data).then((res)=>{
            setError(false)
            settoggleCheckEmail(true)
        }).catch((err)=>{
            setError(true)
        })
    }
    return (
        <>
            {loading ? 
            <>
            <hr/>
            <span>
                <div dangerouslySetInnerHTML={{ __html: staticpage.content}}/>
                <button className="button-newsletter" style={{color:staticpage.color_scritta , backgroundColor:staticpage.color}} onClick={()=>{settoggle(!toggle);}}>{staticpage.testo_bottone}</button>
            </span>
            <div className="inputNewsletter-background" hidden={!toggle}>
            <div className="" style={{textAlign:"center",margin:"20px 0"}}>
                <img src={props.pathlogo} style={{height:"30px", margin:"0 auto"}} alt=""/>
                    <div style={{ fontWeight:"bold",marginRight:"15px",right:"5px",top:"5px",position:"absolute"}} onClick={()=>settoggle(!toggle)}>
                            X
                        </div>
            </div>
            <div hidden={!toggle}>
                <div className="col-lg-4 col-md-6 col-sm-8 col-xs-12 offset-lg-4 offset-md-3 offset-sm-2 contentNewsletter" style={{padding:"15px"}}>
                    
                    <ActiveHostedForm id={"5"}></ActiveHostedForm>
                    {/* <div className="_form_5"></div>
                     <Helmet>
                     <script src="https://giorgiagraziani.activehosted.com/f/embed.php?id=5" type="text/javascript" charSet="utf-8"></script>
                     </Helmet> */}
                    {/* <div style={{wordWrap:"break-word"}}>
                        <div dangerouslySetInnerHTML={{ __html: staticpage.testo_pagina_iscrizione}} />
                    </div>
                    <div>
                    <form onSubmit={(e)=>{saveUser(e)}}>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6" style={{marginBottom:"15px"}}>
                                <div className="input-group">
                                    <input required type="text" className="form-control" value={name} onChange={(event)=>{setName(event.target.value)}} placeholder="Nome" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6" style={{marginBottom:"15px"}}>
                                <div className="input-group">
                                    <input required type="text" className="form-control" value={lastName} onChange={(event)=>{setLastName(event.target.value)}} placeholder="Cognome" />
                                </div>
                            </div>
                            <div className="col-12" style={{marginBottom:"15px"}}>
                                <div className="input-group">
                                    <input required type="email" className="form-control" value={email} onChange={(event)=>{setEmail(event.target.value)}} placeholder="Email" />
                                </div>
                            </div>
                            <div className="col-12">
                                <small style={{textDecoration:"underline", color:"grey",cursor:"pointer"}} onClick={()=>{setShowPolicy(!showPolicy)}}>Policy Privacy</small>
                                <div hidden={!showPolicy} style={{width:"100%", height:"150px",overflow:"auto",border:"1px solid grey"}}>
                                    <div dangerouslySetInnerHTML={{ __html: staticpage.content_privacy_policy}} />
                                </div>
                            </div>
                            <div className="col-12">
                                <input type="checkbox" name="confermack" value={confermack} onClick={()=>{setConfermack(!confermack)}} id=""/>
                                Ho letto la Privacy Policy e acconsento al
                                trattamento dei miei dati personali.
                            </div>
                            <div className="col-12" style={{marginBottom:"15px", marginTop:"15px"}}>
                                <button disabled={confermack!==true} className={confermack===true ? "button-newsletter" : "button-newsletter button-newsletter-opacity"} style={{backgroundColor:staticpage.color, color:staticpage.color_scritta,width:"100%"}}>ISCRIVITI</button>
                            </div>
                        </div>
                    </form> 
                        <div className="col-12">
                            {error !== undefined ? 
                            <span style={error === true ? {color:"red"} : {color:staticpage.color}}>
                                {error===true ? "Errore, riprova oppure scrivi a: info@blackandwire.com" :  ""} 
                            </span>
                            
                            :null
                            }
                        </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="inputNewsletter-background" hidden={!toggleCheckEmail}>
                <div className="" style={{textAlign:"center",margin:"20px 0"}}>
                    <img src={props.pathlogo} style={{height:"30px", margin:"0 auto"}} alt=""/>
                        <div style={{ fontWeight:"bold",marginRight:"15px", position:"absolute",right:"5px",top:"5px"}} onClick={()=>settoggleCheckEmail(!toggleCheckEmail)}>
                                X
                        </div>
                </div>
                <div className="container">
                    <div hidden={!toggleCheckEmail}>
                        <div className="row">
                            <div className="col-lg-12 col-md-6 col-sm-8 col-xs-12 offset-lg-4 offset-md-3 offset-sm-2" style={{margin:"0 auto",width:"100%"}}>
                                <div style={{wordWrap:"break-word"}}>
                                    <div dangerouslySetInnerHTML={{ __html: staticpage.content_checkemail}} />  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            </>
            :null}
        </>
    );
}

export default NewsLetterButton;