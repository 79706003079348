import React,{useContext,useEffect,useState} from 'react'
import {Link} from 'react-router-dom'
import { slide as MenuBurger } from 'react-burger-menu'
import { MenuContext } from "../items/MenuProvider";

let menuitems=[{titolo:"INIZIA QUI",url:"/",active:true},{titolo:"CHI SONO",url:"/chi-sono",active:false},{titolo:"BLOG",url:"/blog",active:false},
{titolo:"I MIEI CORSI",url:"/corsi",active:false}]
var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      left: '15px',
      top: '15px'
    },
    bmBurgerBars: {
      background: '#373a47'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      left:"0",
      top:0
    },
    bmMenu: {
      background: '#373a47',
      fontSize: '1.15em',
      top:"15px"
    },

    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em',
      left:"0",
      top:"0"
    },
    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      top:0,
      left:0
    }
  }

function Menu(props) {
    let { state, dispatch } = useContext(MenuContext);
    const [isOpen, setIsOpen] = useState(false)
    useEffect(()=>{
        dispatch({type:"set",payload:window.location.pathname})
    },[])

    function setNewPage(url) {
      setIsOpen(!isOpen);
      dispatch({type:"set",payload:url});
      
    }
    return(
    props.withMenu === true ? 
      <>
      <MenuBurger isOpen={isOpen} disableAutoFocus styles={styles} className="d-block d-lg-none" >
        <div style={{paddingTop:"30px"}}>
            {menuitems.map((menu,i)=>{
                return(
                  <div key={i} className="col-12" style={{marginTop:"10px"}}>
                    <Link to={menu.url} style={{color:"white",margin:"20px"}} tabIndex="-1" onClick={()=>{setNewPage(menu.url)}} className={state.item===menu.url ? "menuactive itemmenu" :"itemmenu"}> {menu.titolo}</Link>
                  </div>
                )
            })}
          {/* <div className="col-12" style={{marginTop:"10px"}}>
            <a onClick={(e)=>{setIsOpen(!isOpen)}} style={{color:"white",margin:"20px"}} className="itemmenu" target="_blank" href="https://www.etsy.com/it/shop/Blackandwire">SHOP</a>
          </div> */}
        </div>
        </MenuBurger>
      
        <div className="col-12" className="d-none d-lg-block">
          <div className="row" style={{textAlign:"center"}}>
          {menuitems.map((item,i)=>{
            return(
              <div key={i} className="col">
                <Link className={state.item===item.url ? "menuactive itemmenu" :"itemmenu"} onClick={()=>{dispatch({type:"set",payload:item.url})}} to={item.url}>{item.titolo}</Link>
              </div>
            )
            })}
            {/* <div className="col">
              <a className="itemmenu" target="_blank" href="https://www.etsy.com/it/shop/Blackandwire">SHOP</a>
              </div> */}
          </div>
      </div>
    </>
    :null
    )
}

export default Menu;