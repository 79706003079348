import React, { useState,useEffect } from 'react';
import axios from 'axios'
import {BlogPosts} from '../items/BlogPosts'
import SpinnerLoader from '../details/SpinnerLoader';
import MetaHelmet from '../items/MetaHelmet';

function Blog(props) {
  const [post, setPost]=useState([])
  const [intestazionePagina, setIntestazionePagina]=useState(undefined)
  const [pagination, setPagination] = useState(undefined)
  const [loading, setLoading] = useState(undefined)

  useEffect(() => {
    // axios.all([axios.get("/api/v1/blog/"),axios.get('/api/v1/infopage/blog?language='+'it')]).then((...res)=>{
    axios.all([axios.get("/api/v1/blog/"),axios.get("/api/v1/infopage/blog?language=it")]).then((res)=>{
      setPost(res[0].data.results)
      setPagination({count:res[0].data.count, next:res[0].data.next,previus:res[0].data.previus, actual:0})
      setIntestazionePagina(res[1].data)
      setLoading(true);
    })
  }, [])

  return (
    <div className="container"> 
      {loading ? 
      <>
        <div className="paginations">
            {/* <Pagination pagination={pagination} /> */}
        </div>
          <div className="row" style={{textAlign:"center",padding:"0",margin:"0"}}>
            <div className="intestazione col-12">
              <MetaHelmet title={intestazionePagina.gtitle} description={intestazionePagina.gdescription} />
              {intestazionePagina.title !== "" ? <h1>{intestazionePagina.title}</h1> : null}
              {intestazionePagina.content !== "" ? <div style={{textAlign:"left"}} dangerouslySetInnerHTML={{ __html: intestazionePagina.content}}/> : null}
              {/* <nav aria-label="breadcrumb" style={{}}>
                <ol class="breadcrumb" style={{backgroundColor:"white",padding:"0",margin:"0"}}>
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item"><a href="#">Library</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Data</li>
                </ol>
              </nav> */}
            </div>
            {post.map((p,i)=>{
                return(
                  <div key={i} className="col-lg-6 col-md-6 col-sm-6 col-xs-12" style={{marginBottom:"15px"}}>
                    <BlogPosts post={p}/>
                  </div>
                )
            })}
          </div>
        </>  
        :<SpinnerLoader />}
    </div>
  );
}

export default Blog;
