import React, { useState, useEffect } from 'react';
import axios from 'axios'
import ReactPixel from 'react-facebook-pixel';
import '../Payment/payment.css'

function ThankyouPay(props) {
    const [salepages, setSalepages] = useState(null)
    const [page, setSalepage] = useState(null)
    
    useEffect(() => {
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init('704276380318113', advancedMatching, options);
        ReactPixel.pageView()

        let slugcorso=props.match.params.slugcorso
        if(!props.match.params.slugcorso){
            slugcorso="progetto-gioielli"
        }
        axios.get("/api/v1/salepage/"+slugcorso+"/").then((res)=>{
            setSalepage(res.data)
            setSalepages(res.data.salespageitem_set)
            ReactPixel.track('Purchase', { value:res.data.corso.price, currency: 'EUR' })
        })
    }, [])

    return (
        <div style={{marginTop:"30px"}}>
            {page? 
            <>
                <div className="container">
                    <div className="col-lg-7 col-md-9 col-sm-12 col-xs-12" style={{margin:"0 auto"}} 
                    dangerouslySetInnerHTML={{ __html: page.thankyou_page.replace("[[[YOUTUBE_VIDEO]]]",(page.video_youtube ? 
                        '<div class="'+"video-mobile"+'"><iframe style="width:100%;height:100%;"  src="https://www.youtube.com/embed/'+page.video_youtube+"?autoplay=0"+'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>'
                        : ""))
                    }}/>
                </div>
            </>
            :null}
        </div>
    );
}

export default ThankyouPay;