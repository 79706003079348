import React,{useEffect,useState} from 'react'
import axios from 'axios'
import MetaHelmet from '../items/MetaHelmet'
import SpinnerLoader from '../details/SpinnerLoader'
import NewsLetterButton from '../details/NewsLetterButton'

function ChiSono(props) {
    const [page, setPage] = useState(undefined)
    useEffect(() => {
        axios.get('/api/v1/infopage/chi-sono?language=it').then((res)=>{
            setPage(res.data)
        })
    }, [props.match.params.typepage])

    return(
        <div className="container">
            {page !==undefined ?
            <>
                <MetaHelmet title={page.gtitle} description={page.gdescription} />
                    <h1 className="titleinfopage">{page.title}</h1>
                    <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 contentinfopage">
                    <p dangerouslySetInnerHTML={{__html: page.content}} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <div>
                            <img src="/static/image/giorgia-graziani.png" style={{width:"100%"}} alt="Giorgia Graziani - orafa"/>
                        </div>
                        <div style={{textAlign:'center'}}>
                            <a href="https://www.instagram.com/black.and.wire/" style={{textDecoration:"none"}}>
                                <img src="/static/image/Instagram-logo.png" style={{width:"100px"}} alt="Giorgia Graziani - instagram"></img>
                                <div style={{textAlign:'center',color:'black',textDecoration:"none",marginTop:"5px"}}>
                                    Seguimi su instagram!
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
                {/* <div style={{textAlign:"center"}}>
                    <NewsLetterButton pathlogo="/static/logo.png" />
                </div> */}
            </>
            :
            <SpinnerLoader /> }
 
    </div>
)
    
}

export default ChiSono;