import provincie_comuni from './italia_comuni.json'

export function sortByName(a, b){
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0
}
export function sortByNome(a, b){
    if(a.nome < b.nome) { return -1; }
    if(a.nome > b.nome) { return 1; }
    return 0
}
export function sortByImportoNetto(a, b){
    return b.importoNetto - a.importoNetto
}

export function getProvincieLista(){
    let res = []
    provincie_comuni.regioni.forEach(regioni => {
        regioni.province.forEach(provincie => {
            res.push({name:provincie.nome,code:provincie.code})
        });
    });
    res.push({name:"Estera",code:"EE"})
    return res.sort(sortByName);
}

export function getComuniByProvincia(provincia){
    let res = []
    provincie_comuni.regioni.forEach(regioni => {
        regioni.province.forEach(provincie => {
            if(provincie.code === provincia){
                res = provincie.comuni
            }  
        });
    });
    return res;
}
export function getProvinciaNomeByCode(code){
    let res = ""
    if(code === "EE"){
        res = "Estera"
    }else{
       provincie_comuni.regioni.forEach(regioni => {
            regioni.province.forEach(provincie => {
                if(provincie.code === code){
                    res = provincie.nome
                }
            });
        });
    }
    return res
}
