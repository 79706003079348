import React, { useState, useEffect } from 'react';
import axios from 'axios'
import {
    FloatingMenu,
    MainButton,
    ChildButton,
  } from 'react-floating-button-menu';
import { Helmet } from 'react-helmet';
import bottone_mail from '../image/bottoni/bottone_mail.png'
import FloatingMenuContatti from '../details/FloatingMenuContatti';
import CustomizedAccordions from '../details/Accordations';

function compare(a, b) {
    if (a.position > b.position) return 1;
    if (b.position > a.position) return -1;
    return 0;
}

var accordation = [
    {page:"/corsi/progetto-gioielli/salepage",
        items:[
            {
                position:0,
                title:"Per quanto tempo potrò vedere i contenuti del corso?",
                text:"Una volta acquistato il corso avrai l'accesso alla piattaforma con i contenuti per sempre, non solo! Avrai anche la possibilità di accedere ai futuri aggiornamenti del corso."
            },
            {
                position:1,
                title:"Come posso seguire le lezioni?",
                text:"Le lezioni sono già registrate, puoi guardarle da qualsiasi dispositivo connesso a internet"
            },
            {
                position:2,
                title:"Come funziona il pagamento a rate?",
                text:"Al momento dell'acquisto verrà pagata la prima rata, le successive verranno richieste a distanza di un mese tramite il tuo account paypal."
            },
            {
                position:3,
                title:"Posso pagare con bonifico?",
                text:"Si, compila i campi necessari nella pagina del pagamento e poi clicca su bonifico riceverai una email con i dettagli per eseguirlo"
            },
            {
                position:4,
                title:"Entro quando posso iscrivermi?",
                text:"Fino a venerdì 6 maggio alle 23:59, dopodichè non sarà più possibile accedere al corso se non alla prossima edizione (che ancora non so se e quando ci sarà) dove il costo potrebbe aumentare."
            },
            {
                position:5,
                title:"Non ho mai lavorato il filo di metallo/sono principiante, questo mi impedisce di frequentare il corso?",
                text:"Il corso prevede una serie di lezioni ed esercizi che ti permetteranno di creare gioielli anche se non lo hai mai fatto prima!"
            },
            {
                position:6,
                title:"Cosa succede quando acquisto il corso?",
                text:"Ti verrà inviata una mail con username e password da utilizzare per accedere alla piattaforma che contiene il corso a cui potrai accedere ogni volta che vorrai!"
            }
        ]},
        {page:"/corsi/metodo-wire-pro/salepage",
        items:[
            {
                position:0,
                title:"Quando posso seguire il corso?",
                text:"Una volta effettuato il pagamento riceverai una email di benvenuto per accedere alle lezioni, potrai guardarle quando vuoi!"
            },
            {
                position:1,
                title:"Cosa sono i workshop?",
                text:"Sono lezioni in cui tramite delle slide spiego nel dettaglio gli argomenti della lezione."
            },
            {
                position:2,
                title:"Come posso pagare?",
                text:"Tramite carta di debito o credito o tramite paypal."
            },
            {
                position:3,
                title:"Posso seguire l’evento senza il materiale per creare?",
                text:"Certamente, questo non è un corso con tutorial, qui si parla di teoria, metodo, processi da acquisire e molto altro. L'unico tutorial che c'è puoi riadattarlo con i tuoi spessori, lo scopo è capire come progettare, non come fare lo stesso gioiello."
            },
            {
                position:4,
                title:"Quanto dura il corso?",
                text:"I contenuti del corso hanno una durata di 3 ore e 30 minuti, ma devi considerare il tempo più lungo, ovvero quello degli esercizi da fare, inoltre ti consiglio di ripetere il corso dopo 6 mesi per trarre il massimo del risultato."
            }
        ]}
    ]

function Subscribe(props) {
    const [floatingOpen, setFloatingOpen] = useState(false)
    const [salepages, setSalepages] = useState(null)
    const [salepagesMobile, setSalepagesMobile] = useState(null)
    const [page, setSalepage] = useState(null)
    const [pageMobile, setSalepageMobile] = useState(null)
    const [bottone1, setBottone1] = useState("")
    const [bottone2, setBottone2] = useState("")
    const [bottone3, setBottone3] = useState("")
    const [bottone4, setBottone4] = useState("")

    const [bottonemb1, setBottonemb1] = useState("")
    const [bottonemb2, setBottonemb2] = useState("")
    const [bottonemb3, setBottonemb3] = useState("")
    const [bottonemb4, setBottonemb4] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [mail, setMail] = useState("")
    const [prezzo, setPrezzo] = useState("")
    const [prezzoBarrato, setPrezzoBarrato] = useState("")
    const [nome, setNome] = useState(props.match.params.nome ? props.match.params.nome : null)
    const [addedDays, setAddedDays] = useState(5)
    useEffect(() => {
        let slugcorso=props.match.params.slugcorso
        if(!props.match.params.slugcorso){
            slugcorso="progetto-gioielli"
        }
        axios.all([axios.get("/api/v1/salepage/"+slugcorso+"/"), axios.get("/api/v1/salepage/"+slugcorso+"-mobile/")]).then((res)=>{
            setSalepage(res[0].data)
            setSalepageMobile(res[1].data)
            setSalepages(res[0].data.salespageitem_set)
            setSalepagesMobile(res[1].data.salespageitem_set)
            setBottone1("<a href='/corsi/"+slugcorso+"/pay' style='text-decoration:none;'><button id='bottone_1+"+slugcorso+"' class='btn-fbp button-newsletter' style='background-color:[[color]];font-weight:bold;color:[[color_scritta]]'>"+res[0].data.text_button_1+"</button></a>")
            setBottone2("<a href='/corsi/"+slugcorso+"/pay' style='text-decoration:none;'><button id='bottone_2+"+slugcorso+"' class='btn-fbp button-newsletter'style='background-color:[[color]];font-weight:bold;color:[[color_scritta]]'>"+res[0].data.text_button_2+"</button></a>")
            setBottone3("<a href='/corsi/"+slugcorso+"/pay' style='text-decoration:none;'><button id='bottone_3+"+slugcorso+"' class='btn-fbp button-newsletter'style='background-color:[[color]];font-weight:bold;color:[[color_scritta]]'>"+res[0].data.text_button_3+"</button></a>")
            setBottone4("<a href='/corsi/"+slugcorso+"/pay' style='text-decoration:none;'><button id='bottone_4+"+slugcorso+"' class='btn-fbp button-newsletter'style='background-color:[[color]];font-weight:bold;color:[[color_scritta]]'>"+res[0].data.text_button_4+"</button></a>")
            setBottonemb1("<a href='/corsi/"+slugcorso+"/pay' style='text-decoration:none;'><button id='bottone_mb_1+"+slugcorso+"' class='btn-fbp button-newsletter' style='background-color:[[color]];font-weight:bold;color:[[color_scritta]]'>"+res[0].data.text_button_1+"</button></a>")
            setBottonemb2("<a href='/corsi/"+slugcorso+"/pay' style='text-decoration:none;'><button id='bottone_mb_2+"+slugcorso+"' class='btn-fbp button-newsletter'style='background-color:[[color]];font-weight:bold;color:[[color_scritta]]'>"+res[0].data.text_button_2+"</button></a>")
            setBottonemb3("<a href='/corsi/"+slugcorso+"/pay' style='text-decoration:none;'><button id='bottone_mb_3+"+slugcorso+"' class='btn-fbp button-newsletter'style='background-color:[[color]];font-weight:bold;color:[[color_scritta]]'>"+res[0].data.text_button_3+"</button></a>")
            setBottonemb4("<a href='/corsi/"+slugcorso+"/pay' style='text-decoration:none;'><button id='bottone_mb_4+"+slugcorso+"' class='btn-fbp button-newsletter'style='background-color:[[color]];font-weight:bold;color:[[color_scritta]]'>"+res[0].data.text_button_4+"</button></a>")
            setMail("<a style='text-decoration:underline;' href='mailto:"+res[0].data.email+"'>"+res[0].data.email+"</a>")
            setPrezzo("<span>"+(res[0].data.corso.price ? Number(res[0].data.corso.price).toFixed(0) + "€" : "")+"</span>")
            setPrezzoBarrato("<span style='text-decoration: line-through'>"+(res[0].data.corso.strikethrough_price ? Number(res[0].data.corso.strikethrough_price).toFixed(0) + "€" : "")+"</span>")
            
            if ((new Date() >= new Date(res[0].data.corso.validity_from) && new Date() < new Date(res[0].data.corso.validity_to)) 
            || (nome && new Date() < new Date(new Date(res[0].data.corso.validity_to).setDate(new Date(res[0].data.corso.validity_to).getDate()+addedDays)))){
                setIsOpen(true)
            }
        })
    }, [])
    return (
        <div className='contenitore-nopaddingtop'>
            {page && !page.waiting_list_opened ?
            <>
                {/* <div className="container" style={{color:page.corso.color}}>
                    <h1 className={"corso-titolo"}>{page.corso.title}</h1>
                </div> */}
                {isOpen ? 
                <>
                    {/* <div className="container">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{margin:"0 auto"}} dangerouslySetInnerHTML={{ __html: page.corso.subtitle}}/>
                    </div> */}
                    {page.background_image_url && page.background_image_url_mobile ? 
                    <>
                        <div className="d-none d-md-block" style={{backgroundImage:"url('"+page.background_image_url+"')",
                            backgroundPosition:"center",backgroundRepeat:"no-repeat", backgroundSize:"cover",bottom:"0",left:"0",position:"fixed",right:"0",top:"0",zIndex:"-100"}}>                
                        </div>
                        <div className="d-block d-md-none" style={{backgroundImage:"url('"+page.background_image_url_mobile+"')",
                            backgroundPosition:"center",backgroundRepeat:"no-repeat", backgroundSize:"cover",bottom:"0",left:"0",position:"fixed",right:"0",top:"0",zIndex:"-100"}}>                
                        </div>
                    </>
                    :null}
                    {/* DESKTOP */}
                    <div className="d-none d-sm-block">
                        {salepages && salepages.sort(compare).map((salepage,i)=>{
                            return(
                                <div key={i} className={"container-fluid"} style={!salepage.center ? {backgroundColor:salepage.background_color === "#FFFFFF" ? "transparent !important" : salepage.background_color  ,color:salepage.font_color}:null}>
                                    <div className="container" style={ salepage.center ? {backgroundColor:salepage.background_color,color:salepage.font_color,margin:"0 auto"}:{margin:"0 auto"}}>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{margin:"0 auto"}}>
                                            {salepage.text.includes("[[[FISARMONICA]]]") && accordation.find(x=>x.page === window.location.pathname) ?
                                                <div>
                                                    {window.location.pathname.includes(window.location.pathname) ? 
                                                        <div style={{margin:"0 auto", width:"100%",maxWidth:"700px",paddingBottom:"10px"}}>
                                                            <div style={{padding:"15px 0",color:"#082d4b",fontSize:"18px",fontWeight:"bold",textAlign:'center'}}>
                                                                Prima di pendere una decisione, leggi le risposte alle domande frequenti!:</div>
                                                                <CustomizedAccordions items={accordation.find(x=>x.page === window.location.pathname).items}></CustomizedAccordions>
                                                        </div>
                                                    :null}
                                                </div> 
                                                : 
                                                <div className={"saleitem"} style={{textAlign:"left"}} 
                                                    dangerouslySetInnerHTML={{ __html: salepage.text.replace("[[[BOTTONE_1]]]",bottone1).replace("[[[BOTTONE_2]]]",bottone2)
                                                    .replace("[[[BOTTONE_3]]]",bottone3).replace("[[[BOTTONE_4]]]",bottone4).replace("[[[MAIL]]]",mail)
                                                    .replace("[[color]]", salepage.background_color !== "#FFFFFF" ?  "#FFFFFF" : page.corso.color )
                                                    .replace("[[color_scritta]]",salepage.background_color === "#FFFFFF" ?  "#FFFFFF" : page.corso.color)
                                                    .replace("[[[PREZZO_BARRATO]]]",prezzoBarrato)
                                                    .replace("[[[PREZZO]]]",prezzo)
                                                    .replace("[[[YOUTUBE_VIDEO]]]",(salepage.video_youtube ? 
                                                        '<div class="'+(salepage.picture_in_picture ? "pictruceinpictruce" : "video-nomobile")+'"><iframe style="width:100%;height:100%;"  src="https://www.youtube.com/embed/'+salepage.video_youtube+"?autoplay=0"+'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>'
                                                        : ""))
                                                    }}/>
                                                }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* MOBILE */}
                    <div className="d-block d-sm-none">
                        {salepagesMobile && salepagesMobile.sort(compare).map((salepage,i)=>{
                            return(
                                <div key={i} className={"container-fluid"} style={!salepage.center ? {backgroundColor:salepage.background_color,color:salepage.font_color}:null}>
                                    <div className="container" style={ salepage.center ? {backgroundColor:salepage.background_color,color:salepage.font_color,margin:"0 auto"}:{margin:"0 auto"}}>
                                            {salepage.text.includes("[[[FISARMONICA]]]") && accordation.find(x=>x.page === window.location.pathname) ?
                                                <div>
                                                    {window.location.pathname.includes(window.location.pathname) ? 
                                                        <div style={{margin:"0 auto", width:"100%",maxWidth:"700px",paddingBottom:"10px"}}>
                                                            <div style={{padding:"15px 0",color:"#082d4b",fontSize:"18px",fontWeight:"bold",textAlign:'center'}}>
                                                                Prima di pendere una decisione, leggi le risposte alle domande frequenti!:</div>
                                                                <CustomizedAccordions items={accordation.find(x=>x.page === window.location.pathname).items}></CustomizedAccordions>
                                                        </div>
                                                    :null}
                                                </div>  
                                            : 
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{margin:"0 auto"}}>
                                                <div className={"saleitem"} style={{textAlign:"left"}} dangerouslySetInnerHTML={{ __html: salepage.text.replace("[[[BOTTONE_1]]]",bottonemb1).replace("[[[BOTTONE_2]]]",bottonemb2)
                                                .replace("[[[BOTTONE_3]]]",bottonemb3).replace("[[[BOTTONE_4]]]",bottonemb4).replace("[[[MAIL]]]",mail)
                                                .replace("[[color]]", salepage.background_color !== "#FFFFFF" ?  "white" : page.corso.color ).replace("[[color_scritta]]",salepage.background_color === "#FFFFFF" ?  "white" : page.corso.color)
                                                .replace("[[[PREZZO_BARRATO]]]",prezzoBarrato)
                                                .replace("[[[PREZZO]]]",prezzo)
                                                .replace("[[[YOUTUBE_VIDEO]]]",(salepage.video_youtube ? 
                                                    '<div class="'+"video-mobile"+'"><iframe style="width:100%;height:100%;"  src="https://www.youtube.com/embed/'+salepage.video_youtube+"?autoplay=0"+'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>'
                                                    : ""))
                                                }}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <div className="countdown-baw">
                        <div className="countdown-baw-inner">
                            <CountdownTimer style={{margin:"0 auto"}} count={5432}  showTitle noPoints />
                        </div>
                    </div> */}
                </>: 
                    <div className={"container-fluid"}>
                        <div className="container" style={{margin:"0 auto"}}>
                            <div dangerouslySetInnerHTML={{ __html: page.corso.descrizione}} />
                            
                        </div>
                    </div>
                }
                <Helmet>
                    <script type="text/javascript" src="/static/scripts/FBButtonEventListener.js"></script>
                    <script type="text/javascript" src="/static/scripts/smartsupp.js"></script>
                </Helmet>
            </>
            :<div className="container">
                
            </div>}

            {/* <FloatingMenuContatti 
                mail={"info@blackandwire.com"} 
                subject={"[Salepage] Informazioni corso"}
                floatingOpen={floatingOpen}
                bottone_mail={bottone_mail}>
            </FloatingMenuContatti> */}
            </div>
        );
}

export default Subscribe;