import React,{useState,useEffect} from 'react'
import './css/newsLetter.css'
import axios from 'axios'
import MetaHelmet from '../items/MetaHelmet'
import {PageView, initGA, Event} from '../Tracking'; //https://medium.com/@malith.dev/track-users-in-your-react-app-with-google-analytics-6364ebfcbae8
import bottone_mail from '../image/consulenze/imm_Domande.png'
import ReactPixel from 'react-facebook-pixel';
import FloatingMenuContatti from '../details/FloatingMenuContatti'
import GiorgiaImmagine from '../image/consulenze/Giorgia-Graziani-Consulenze-chiusura.png'
import LogoConsulenze from '../image/consulenze/logo-consulenze.png'

import ActiveHostedForm from '../details/ActiveHostedForm';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
//https://medium.com/@zoltankohalmy/react-and-django-57f949b0f012

function ConsulenzeCloseLP(props) {    
    useEffect(() => {
        initGA()
        PageView()
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init('704276380318113', advancedMatching, options);
    }, [])

    return(
        <>
        <div className="container-fluid monserrat-font" style={{marginTop:"70px"}}>
            <MetaHelmet title={"Super creativa - la consulenza per la tua crescita creativa"} description={"la consulenza per la tua crescita creativa"} />
            <div className="container-fluid row">
                <div className="container">
                    <div className="row block-space">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div style={{width:"100%", maxWidth:"400px",margin:"0 auto"}}>
                                <div>
                                    <img src={LogoConsulenze} alt="Consulenze Black And Wire" style={{width:"100%",maxWidth:"300px"}} />
                                </div>
                                    <div style={{fontSize:"18px",padding:"10px 0",fontWeight:"600"}}>
                                        Mi dispiace! <br /> Per ora le nuove iscrizioni sono chiuse.
                                    </div>
                            </div>
                            <div style={{width:"100%", maxWidth:"400px",margin:"0 auto"}}>
                                <div style={{fontSize:"13px",paddingTop:"20px"}}>
                                    Inserisci la tua email qui se vuoi essere tra le prime persone a sapere quando riapriranno!
                                </div>
                                <ActiveHostedForm moreclass={"nopadding-nomargin"} id={19}></ActiveHostedForm>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" style={{textAlign:'center',paddingTop:"20px"}}>
                            <img src={GiorgiaImmagine} alt="GiorgiaConsulenze" style={{width:"100%"}} />
                        </div>
                    </div>
                </div>
            </div>
                <FloatingMenuContatti 
                    mail={"supercreativa@blackandwire.com"} 
                    subject={"[Salepage] Informazioni corso"}
                    floatingOpen={false}
                    bottone_mail={bottone_mail}>
                </FloatingMenuContatti>
            
        </div>
        </>
    )
}
export default ConsulenzeCloseLP;