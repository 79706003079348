import React from 'react'
import { Helmet } from 'react-helmet';
import settings from  '../../settings.json'

function MetaHelmet(props) {
    return(
        <Helmet >
        <title>{props.title} - {settings.url}</title>
        <meta name="description" content={props.description} />        
        <meta name="theme-color" content="#2196F3" />
        <meta property="og:title" content={props.title} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={props.description} />
        {props.url ? <meta property="og:url" content={props.url} /> :null } 
        {props.image ? <meta property="og:image" content={props.image} /> :null } 
      </Helmet>
    )
}
export default MetaHelmet;