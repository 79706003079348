import React, {useState,useEffect} from 'react';
import axios from 'axios'
import SpinnerLoader, { SpinnerLoaderCorso } from '../details/SpinnerLoader';
import HeaderCorsi from './HeaderCorsi';
import BottoneCorsi from '../items/BottoneCorsi';

function ListaCorsi(props) {
    const [listOfCourse, setListOfCourse] = useState([])
    const [loading, setLoading] = useState(true)
    const [listofMyCourse, setListofMyCourse] = useState([])
    const [tk, setTk] = useState(null)
    const [name, setName] = useState(null)
    const [loadingButton, setLoadingButton] = useState(true)

    useEffect(() => {
        axios.get("/api/v1/corso/").then(res=>{
            setListOfCourse(res.data)
            setLoading(false)
            setTk(localStorage.getItem('tk'))
            setLoadingButton(false)
        })
    }, [])

    useEffect(() => {
        if(tk){
            let data = {
                token:tk
            }
            axios.post("/api/v1/corsi/listofmycourse/",data).then(res=>{
                setListofMyCourse(res.data.corso_list)
                setLoadingButton(false)
                setName(localStorage.getItem('name'))
            }).catch(error=>{
                setLoadingButton(false)
                localStorage.removeItem('tk')
                localStorage.removeItem('name')
                setTk(null)
                setName(null)
                window.location.reload(true);
            })
        }
    }, [tk])

    return (
        <div className="container"> 
        {!loading ?
            <div>
                <HeaderCorsi></HeaderCorsi>
                <hr/>
                <div className="row" style={{fontFamily:"Roboto",fontWeight:"100",marginBottom:"15px",paddingBottom:"15px"}}>
                {listOfCourse.map((corso,i)=>{
                    return(
                        <>
                            {corso.active || listofMyCourse.map(item => item.corso).includes(corso.id) ?
                                <div key={i} className="col-lg-6 col-md-6 col-sm-6 col-xs-12" style={{textAlign:'center',padding:"15px"}}>
                                    <div>
                                        {corso.file?
                                            <img src={corso.file.split("?")[0]} style={{height:"100%",maxHeight:"100px"}} alt={corso.alt}/>
                                        :null}
                                    </div>
                                    <div>
                                        <div className="corso-descrizione" dangerouslySetInnerHTML={{ __html: corso.subtitle}}></div>
                                    </div>
                                    <div>
                                    {loadingButton ? 
                                        <div className="">
                                            <SpinnerLoaderCorso color={corso.ButtonColor} />
                                        </div>
                                        : 
                                        <div className="">
                                            {listofMyCourse ? 
                                                <BottoneCorsi corso={corso} listofMyCourse={listofMyCourse}></BottoneCorsi>
                                            :
                                            <SpinnerLoaderCorso color={props.corso.ButtonColor} />}
                                        </div>
                                    }
                                    </div>
                                    <hr/>
                                </div>
                            :null}                         
                            
                            {/* <div className="col-12" style={{margin:"0 auto"}}>
                                <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{textAlign:"center"}}>
                                    {corso.file?
                                        <img src={corso.file.split("?")[0]} style={{width:"100%",maxWidth:"150px"}} alt={corso.alt}/>
                                    :null}
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-10">
                                    <div className="row">
                                        <div className="col-12 corso-titolo">
                                            <h1 style={{color:corso.color,fontSize:"40px",textAlign:"left"}}>{corso.title}</h1>
                                        </div>
                                        <div className="col-12">
                                            <div className="corso-descrizione" dangerouslySetInnerHTML={{ __html: corso.subtitle}}></div>
                                        </div>
                                    </div>
                                </div>
                                {loadingButton ? 
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                        <SpinnerLoaderCorso color={corso.ButtonColor} />
                                    </div>
                                    : 
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                        {listofMyCourse ? 
                                            <BottoneCorsi corso={corso} listofMyCourse={listofMyCourse}></BottoneCorsi>
                                        :
                                        <SpinnerLoaderCorso color={props.corso.ButtonColor} />}
                                    </div>
                                }
                                </div>
                            </div> */}
                        </>
                    )
                })}
                </div>
                {/* {name && tk ? null : 
                    <div style={{textAlign:"center"}}>
                        <span style={{marginRight:"15px"}}>
                            <div>Sei già iscritto?</div>
                            <a href="/corsi/login">
                                <button className="button-newsletter" style={{color:"white" , backgroundColor:"#2197F3",padding:"10px 30px"}}>ACCEDI</button>
                            </a>
                        </span>
                    </div>
                } */}
            </div>
        :<SpinnerLoader />}
        </div>
    );
}

export default ListaCorsi;