import React,{useEffect,useState} from 'react'
import axios from 'axios'
import MetaHelmet from '../items/MetaHelmet'
import SpinnerLoader from '../details/SpinnerLoader'
import {PageView, initGA} from '../../components/Tracking';
import ReactPixel from 'react-facebook-pixel';
import ActiveHostedForm from '../details/ActiveHostedForm';
import CustomizedAccordions from '../details/Accordations';

function InfoPage(props) {
    const [page, setPage] = useState(undefined)
    const [activecampaingId, setActivecampaingId] = useState(null)
    var accordation = [
        {page:"/dalla-progettazione-alla-creazione",
            items:[
                {
                    position:0,
                    title:"Cosa succede se non posso seguire le live?",
                    text:"Le live del 28 aprile e del 2 maggio verranno salvate, verrà inviata la registrazione a chi è iscritto all'evento via email il giorno successivo. (rimangono disponibili fino al 6 maggio)"
                },
                {
                    position:1,
                    title:"Come posso seguire le lezioni?",
                    text:"Saranno inviate via email, le prime 3 sono registrate, quindi potrai guardarle nel momento della giornata che preferisci. (rimangono disponibili fino al 6 maggio)"
                },
                {
                    position:2,
                    title:"Fino a quando saranno disponibili i contenuti dell'evento?",
                    text:"Le lezioni, le registrazioni delle live e il gruppo FB dedicato all'evento saranno disponibili fino al 6 maggio 2022."
                },
                {
                    position:3,
                    title:"Posso seguire l'evento senza il materiale per creare?",
                    text:"Per comodità ho fatto la lista dei materiali che utilizzo io nei tutorial, ma puoi comunque seguire le lezioni senza fare la parte pratica (ci sarà un esercizio di progettazione anche per chi non ha materiale). Inoltre nel workshop del 2 maggio i contenuti saranno tutti teorici, quindi prendi carta e penna!"
                },
            ]},
            {page:"/da-zero-a-saldato",
            items:[
                {
                    position:0,
                    title:"Cosa succede se non posso seguire le live?",
                    text:"Le live di giovedì e lunedì saranno salvate, verrà inviata la registrazione a chi è iscritto all'evento via email il giorno successivo. (rimangono disponibili fino al 28 ottobre)"
                },
                {
                    position:1,
                    title:"Come posso seguire le lezioni?",
                    text:"Saranno inviate via email, le prime 3 sono registrate, quindi potrai guardarle nel momento della giornata che preferisci. (rimangono disponibili fino al 28 ottobre)"
                },
                {
                    position:2,
                    title:"Fino a quando saranno disponibili i contenuti dell'evento?",
                    text:"Le lezioni, le registrazioni delle live e il gruppo FB dedicato all'evento saranno disponibili fino al 28 ottobre 2022."
                },
                {
                    position:3,
                    title:"Posso seguire l'evento senza il materiale per creare?",
                    text:"Per comodità ho fatto la lista dei materiali che utilizzo io nei tutorial, ma puoi comunque seguire le lezioni senza fare la parte pratica. Inoltre nel workshop del 24 ottobre i contenuti saranno tutti teorici, quindi prendi carta e penna!"
                },
            ]},
            {page:"/primi-passi-per-saldare",
            items:[
                {
                    position:0,
                    title:"Cosa succede se non posso seguire le live?",
                    text:"Le live di giovedì e lunedì saranno salvate, verrà inviata la registrazione a chi è iscritto all'evento via email il giorno successivo. (rimangono disponibili fino al 17 febbraio)"
                },
                {
                    position:1,
                    title:"Come posso seguire le lezioni?",
                    text:"Saranno inviate via email, le prime 3 sono registrate, quindi potrai guardarle nel momento della giornata che preferisci. (rimangono disponibili fino al 17 febbraio)"
                },
                {
                    position:2,
                    title:"Fino a quando saranno disponibili i contenuti dell'evento?",
                    text:"I contenuti dell'evento saranno disponibili fino al 17 febbraio 2023."
                },
                {
                    position:3,
                    title:"Posso seguire l'evento senza il materiale per creare?",
                    text:"Per comodità ho fatto la lista dei materiali che utilizzo io nei tutorial, ma puoi comunque seguire le lezioni senza fare la parte pratica. Inoltre nel workshop del 13 febbraio i contenuti saranno tutti teorici, quindi prendi carta e penna!"
                },
            ]}]

    function getAndChageEmbedac(pag_content){
        var i = 0;
        if( pag_content.indexOf('[embedac_') > -1){   
            var start = pag_content.indexOf('[embedac_')
            var end = 9
            while(pag_content[start+end] !== ']'){
                end = end + 1
            }
            var numberForm = pag_content.substring(start+9,start+end)
            setActivecampaingId(Number(numberForm))
            var changeString = pag_content.substring(start,start+end+1)
            pag_content = pag_content.replace(changeString, '')
            i=i+1;
        }
        return pag_content
    }
    const createFullPostMarkup = (page_content) => {
        return { __html: page_content }
    }

    useEffect(() => {
        initGA()
        PageView()
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init('704276380318113', advancedMatching, options);
        ReactPixel.pageView();

        axios.get('/api/v1/infopage/'+props.match.params.typepage+'?language=it').then((res)=>{
            var pag = res.data
            pag.content = getAndChageEmbedac(pag.content)
            setPage(pag)
        }).catch(error=>{
            window.location.href = "/404";
        })
    }, [props.match.params.typepage])

    return(
        <div className="container-fluid">
            {page !==undefined ?
            <>
                <MetaHelmet title={page.gtitle} description={page.gdescription} />
                <h1 className="titleinfopage">{page.title}</h1>
                <p className="contentinfopage" dangerouslySetInnerHTML={{__html: page.content}} />
                <div id="iscrizione">
                    {activecampaingId ? 
                        <ActiveHostedForm id={activecampaingId}></ActiveHostedForm>
                    :null}
                </div>
                {window.location.pathname === '/primi-passi-per-saldare' ? 
                    <div style={{margin:"0 auto", width:"100%",maxWidth:"700px"}}>
                        <div style={{padding:"15px 0",color:"#082d4b",fontSize:"25px"}}>Domande Frequenti:</div>
                            <CustomizedAccordions items={accordation.find(x=>x.page === window.location.pathname).items}></CustomizedAccordions>
                    </div>
                :null}
            </>
            :
            <SpinnerLoader /> }
 
    </div>
)
    
}

export default InfoPage;