import React,{useState,useEffect} from 'react';
import axios from 'axios'

function Login(props) {
  const [loggato, setLoggato] = useState(false)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [recoverPassword, setRecoverPassword] = useState(false)
  const [clickedChangePassword, setClickedChangePassword] = useState(false)

  function loginUser(e){
    e.preventDefault()
    setLoading(true)
    let head ={'username':username, 'password':password}
    axios.get("/api/v1/user/login/",{headers:head}).then((res)=>{
      localStorage.setItem("tk",res.data.token)
      localStorage.setItem("name",res.data.name)
      setLoading(true)
      setLoggato(true)
      window.location.href = '/corsi'
    }).catch((error)=>{
      if(error.response.status === 401){
        setMessage("Non risulti iscritto a nessun corso")
      }else{
        setMessage("Errore, username o password non corrette!")
      }
      setLoading(true)
    })
  }
  function sendEmailRecoverPassword(e){
    e.preventDefault();
    setClickedChangePassword(!clickedChangePassword); 
    setMessage("Attendi completamento operazione...")
    let data={
      username:username,
      type:"request_change_password",
      gurl:props.match.params.slugcorso
    }
    axios.put("/api/v1/user/recoverpassword/",data).then((res)=>{
      if(res.data.msg){
        setMessage(res.data.msg)
      }
    }).catch(()=>{
      setMessage("Qualcosa è andato storto, contattami ad info@blackandwire.com se non riesci a proseguire")
      setClickedChangePassword(false)
    })
  }
    return (
        <div className="container login-container">
          <div className="row padding-header h-100">
            <div className="col-md-6 login-form-2" style={{margin:"0 auto"}}>
              {!recoverPassword ? 
                <form onSubmit={(e)=>{loginUser(e)}}>
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Your Email *" value={username} onChange={(e)=>{setUsername(e.target.value)}} />
                    </div>
                    <div className="form-group">
                        <input type="password" className="form-control" placeholder="Your Password *" value={password} onChange={(e)=>{setPassword(e.target.value)}} />
                    </div>
                    <div className="form-group">
                        <input type="submit" className="btnSubmit" value="Login" />
                    </div>
                    <div className="form-group">
                        <span className="ForgetPwd" value="Login" onClick={(e)=>{e.preventDefault();setRecoverPassword(!recoverPassword)}}>Password dimenticata?</span>
                    </div>
                </form>
              :
                <form onSubmit={(e)=>{sendEmailRecoverPassword(e)}}>
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Your Email *" value={username} onChange={(e)=>{setUsername(e.target.value)}} />
                    </div>
                    <div className="form-group">
                        {!clickedChangePassword ?
                          <input hidden={clickedChangePassword} type="submit" className="btnSubmit" value="Cambia Password" />
                          :null}
                    </div>
                    <div className="form-group">
                        <span className="ForgetPwd" value="Login" onClick={(e)=>{e.preventDefault();setRecoverPassword(!recoverPassword);setMessage("")}}>Torna al login</span>
                    </div>
                </form>}
            </div>
          </div>
          {message ? 
            <div className="col-md-6" style={{margin:"0 auto",marginTop:"5px",padding:"5px",backgroundColor:"#FFA500",textAlign:"center",color:"black"}}>
              <h5>{message}</h5>
            </div>
          :null}
        </div>
    );
}

export default Login;