import React,{useState,useEffect} from 'react';
import HeaderCorsi from './HeaderCorsi';
import axios from 'axios'
import {PrecompilatoFattura,savePdf} from './PrecompilatoFattura'
import moment from 'moment'
  
function Profile(props) {
    const [listOfPayment, setListOfPayment] = useState([])
    
    function scaricaFattura(payment){
        savePdf(<PrecompilatoFattura payment={payment} />, 'fattura_'+moment(payment.timestamp).format('DD-MM-YYYY')+'_'+payment.num_fattura+'.pdf');
    }
    useEffect(() => {
        let data = {
            token:localStorage.getItem('tk')
        }
        axios.post('/api/v1/corsi/listofmypayment/',data).then((res)=>{
            setListOfPayment(res.data.data)
        })
    }, [])

    return (
        <div className="container">
            <HeaderCorsi></HeaderCorsi>
            <hr/>
            <div className="row">
                <div className="col-12">
                    <div>
                        <h4>I tuoi pagamenti:</h4>
                    </div>
                    <div class="table-responsive-sm">
                    <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Data</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Cognome</th>
                        <th scope="col">Riferimento</th>
                        <th scope="col">Metodo di pagamento</th>
                        <th scope="col">Pagato(€)</th>
                        <th scope="col">Codice fiscale/P. iva</th>
                        <th scope="col">Stato</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listOfPayment.map((payment,i)=>{
                            return(
                            <tr key={i}>
                                <th scope="row">{i+1}</th>
                                <td>{payment.timestamp.split("T")[0]}</td>
                                <td>{payment.name}</td>
                                <td>{payment.lastname}</td>
                                <td>{payment.corso_name}</td>
                                <td>{payment.method_payment}</td>
                                <td>{payment.paid}</td>
                                <td>{payment.vat_number}</td>
                                
                                <td>
                                    {payment.inattesa || !payment.num_fattura ? "IN ATTESA" : 
                                    <div>
                                        {Number(payment.paid)>77.47 ?
                                            !payment.codice_marca ? 
                                                <span>CONFERMATO</span>
                                                :
                                                <span>CONFERMATO <a href="javascript:void()" onClick={()=>scaricaFattura(payment)}><i className="fas fa-file-download"></i></a></span>
                                        :
                                            <span>CONFERMATO <a href="javascript:void()" onClick={()=>scaricaFattura(payment)}><i className="fas fa-file-download"></i></a></span>
                                        }
                                    </div>
                                    }
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;