import React,{useState,useEffect} from 'react'
import './css/newsLetter.css'
import axios from 'axios'
import MetaHelmet from '../items/MetaHelmet'
import {PageView, initGA, Event} from '../Tracking'; //https://medium.com/@malith.dev/track-users-in-your-react-app-with-google-analytics-6364ebfcbae8
import FooterInfo from '../details/FooterInfo'
import bookSaldatura from '../image/book_saldatura.png'
import bookWire from '../image/book_wire.png'
import onda_desktop from '../image/onda_desktop.png'

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
//https://medium.com/@zoltankohalmy/react-and-django-57f949b0f012

let categorypage="NEWSLETTER";
let actionsubscription="Iscrizione confermata";
let actionunsubscription="Disiscrizione confermata";

function HomepageInstagram(props) {
    const [toggleThanks, settoggleThanks] = useState(false)
    const [toggleGoodBye, settoggleGoodBye] = useState(false)

    useEffect(() => {
        initGA()
        PageView()
        if(window.location.pathname==="/subscription-success"){
            settoggleThanks(true)
            Event(categorypage,actionsubscription,"ISCRIZIONE_CONFERMATA")
        }else if(window.location.pathname==="/unsubscription-success"){
            settoggleGoodBye(true)
            Event(categorypage,actionunsubscription,"DISISCRIZIONE_CONFERMATA")
        }
        let gurl = "null"
        if(props.match.params.gurl){
            gurl = props.match.params.gurl
        }
    }, [])

    return(
        <>            
            <div className="container-fluid" style={{marginTop:"-30px"}}>
                <MetaHelmet title={"Corsi gioielli e saldatura - blackandwire.com"} description={"Iscriviti alla newsletter dei corsi di gioielli e saldatura."} />
                <div style={{paddingTop:"100px",paddingBottom:"35px",textAlign:'center'}}>
                    <div style={{marginBottom:"20px"}}>
                        <div className="gradientblue" style={{height:"400px",top:"-100px",right:"0",left:"-100px",overflowX:"none",zIndex:"-1",
                                position:"absolute", transform: "skewY(-5deg)",overflow:'hidden'}}>
                            </div>
                        {/* https://getwaves.io/ */}
                        <div className={"d-block d-sm-none"}>{/*mobile*/}
                            <div style={{fontWeight:"bold",fontSize:"20px",paddingBottom:"30px",width:"100%",marginBottom:"25px",color:'white'}}>
                                ECCO I MIEI CONTENUTI <br/> GRATUITI PER TE!
                            </div>
                        </div>
                        <div className={"d-none d-sm-block"}>{/*desktop*/}
                            <div style={{fontWeight:"bold",fontSize:"28px",paddingBottom:"30px",marginBottom:"50px",color:'white'}}>
                                ECCO I MIEI CONTENUTI <br/> GRATUITI PER TE!
                            </div>
                        </div>
                        <a style={{color:'inherit'}} href="https://blackandwire.com/newsletter/guida-wire">
                            <div className="col-12" style={{paddingBottom:"15px",marginBottom:"30px"}}>
                            <div className="gradientblue" style={{height:"100%",top:"0",right:"0",left:"-100px",overflowX:"none",zIndex:"-1",
                                position:"absolute", transform: "skewY(-5deg)",overflow:'hidden'}}>
                            </div>
                                <div className="row">
                                    <div className="col-6" style={{textAlign:"right",padding:"0"}}>
                                        <img src={bookWire} style={{maxWidth:"150px"}} alt=""/>
                                    </div> 
                                    <div className="col-6" style={{textAlign:"left",position: "relative",padding:"0"}}>
                                        <div style={{height:"100%",display:"table",height:"100%",width: "100%", tableLayout: "fixed"}}>
                                            <div className="triangolo_destro" style={{display: "table-cell",fontWeight:"bold",  verticalAlign: "middle",textAlign:'left',color:'white'}}>
                                            Scarica la guida gratuita sulla tecnica wire
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a style={{color:'inherit'}} href="https://blackandwire.com/newsletter/guida-saldatura" target="_blank">
                            <div className="col-12" style={{paddingBottom:"15px"}}>
                            <div className="row">
                                    <div className="col-6" style={{textAlign:"right",position: "relative",padding:"0"}}>
                                        <div style={{height:"100%",display:"table",height:"100%",width: "100%", tableLayout: "fixed"}}>
                                            <div style={{display: "table-cell", tableLayout:"fixed", verticalAlign: "middle",textAlign:'right',fontWeight:"bold",color:"#154067"}}>
                                                Scarica la guida gratuita sulla saldatura
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6" style={{textAlign:"left",padding:"0"}}>
                                        <img src={bookSaldatura} style={{maxWidth:"150px"}} alt=""/>
                                    </div> 
                                </div>
                            </div>
                        </a>
                    </div>    
                    <FooterInfo isFixed={true}/>
                </div>
            </div>
        </>
    )
}
export default HomepageInstagram;