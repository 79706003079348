import React, { useState,useEffect } from 'react';
import axios from 'axios'
import {SpinnerLoaderCorso} from '../details/SpinnerLoader';
import Modal from 'react-modal';

function ChangePassword(props) {
  const [rePassword, setRePassword] = useState("")
  const [corso, setCorso] = useState(null)
  const [password, setPassword] = useState("")
  const [message, setMessage] = useState("")
  const [id_password, setId_password] = useState(props.match.params.id_password)
  
  let header = {'token':localStorage.getItem('tk')}
  useEffect(() => {
    axios.get("/api/v1/corso/"+props.match.params.slugcorso+"/", {headers:header}).then((res)=>{
     setCorso(res.data)
    })
  }, [])
//   function sendEmailRecoverPassword(e){
//     e.preventDefault();
//     setClickedChangePassword(!clickedChangePassword); 
//     setMessage("Attendi completamento operazione...")
//     let data={
//       username:username,
//       type:"request_change_password",
//       gurl:props.match.params.slugcorso
//     }
//     axios.put("/api/v1/"+corso.id+"/user/",data).then((res)=>{
//       if(res.data.msg){
//         setMessage(res.data.msg)
//       }
//     }).catch(()=>{
//       setMessage("Qualcosa è andato storto, contattami ad info@blackandwire.com se non riesci a proseguire")
//       setClickedChangePassword(false)
//     })
//   }

    function changePassword(e){
        e.preventDefault()
        if(password!==rePassword){
            setMessage("Le due password devono essere uguali")
        }else{
            setMessage("Attendi completamento operazione...")
            let data={
                id_new_password:id_password,
                type:"change_password",
                gurl:props.match.params.slugcorso,
                new_password:password
            }
            axios.put("/api/v1/user/recoverpassword/",data).then((res)=>{
                if(res.data.msg){
                    setMessage(res.data.msg)
                    setTimeout(() => {
                      window.location.href = "/corsi/"
                    }, 2000);
                }
            }).catch(()=>{
                setMessage("Qualcosa è andato storto, contattami ad info@blackandwire.com se non riesci a proseguire")
            })
        }  
    }
  return (
 
        <div className="container login-container">
          <div className="row padding-header h-100">
            <div className="col-md-6 login-form-2" style={{margin:"0 auto"}}>
                <form onSubmit={(e)=>{changePassword(e)}}>
                    <div className="form-group">
                        <input required type="password" className="form-control" placeholder="New Password *" value={password} onChange={(e)=>{setPassword(e.target.value)}} />
                    </div>
                    <div className="form-group">
                        <input required type="password" className="form-control" placeholder="Repeat Password *" value={rePassword} onChange={(e)=>{setRePassword(e.target.value)}} />
                    </div>
                    <div className="form-group">
                        <input type="submit" className="btnSubmit" value="Salva" />
                    </div>
                </form>
              <div style={{textAlign:"center",color:"white"}}>
                {message}
              </div>
            </div>
          </div>
        </div>
      
  );
}

export default ChangePassword;
