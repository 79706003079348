import * as React from "react";

let Menu = React.createContext();

let initialState = {
  item: ""
};

let reducer = (state, action) => {
  switch (action.type) {
    case "set":
      return {...state, item:action.payload};
    default:
        return state
  }
};

function MenuProvider(props) {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  return (
    <Menu.Provider value={value}>{props.children}</Menu.Provider>
  );
}

let MenuConsumer = Menu.Consumer;
export { Menu as MenuContext, MenuProvider, MenuConsumer };