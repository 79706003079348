import React from 'react';

function FooterInfo(props) {
    const isFixed = props.isFixed === true ? true : false

    let styleFixed={color:"#ffffff",backgroundColor:"#545454",bottom:0,left:"0",right:"0",position:"fixed" , zIndex:100}
    let styleNoFixed={color:"#ffffff",backgroundColor:"#545454", width:"100%", zIndex:100,position:"absolute",bottom:0}
    return (
        <div className="col-12" style={isFixed ? styleFixed : styleNoFixed}>
            <div className="row ">
                <div className="col-lg-8 col-md-8 d-none d-md-block">
                        <span>Giorgia Graziani | P.IVA 01649180559</span>                        
                        <span style={{fontWeight:"bold",fontSize:"25px",marginLeft:"30px"}}>
                            <a href="https://www.facebook.com/blackandwire/" style={{color:"#ffffff"}}><i className="fab fa-facebook" aria-hidden="true"></i></a>
                            <a href="http://instagram.com/black.and.wire" style={{marginLeft:"15px",color:"#ffffff"}}><i className="fab fa-instagram" aria-hidden="true"></i></a>
                            <a href="https://www.youtube.com/channel/UCG3HWwB6Lg91GwV0NONItlg?view_as=subscriber" style={{marginLeft:"15px",color:"#ffffff"}}><i className="fab fa-youtube" aria-hidden="true"></i></a>
                            <a href="https://www.pinterest.it/blackandwire" style={{marginLeft:"15px",color:"#ffffff"}}><i className="fab fa-pinterest" aria-hidden="true"></i></a>
                        </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-none d-md-block">
                    <div style={{paddingsRight:"50px",textAlign:"right",fontWeight:"bold",textDecoration:"underline"}}>
                        <a href="https://blackandwire.com/policy-privacy" style={{color:"#ffffff"}} target="_black" rel="noopener noreferrer">
                            <div style={{padding:"5px"}}>Policy Privacy</div>
                        </a>
                    </div>
                </div>
                <div className="col-sm-12 col-xs-12 d-block d-md-none" style={{textAlign:'center'}}>
                 <span style={{fontSize:"13px"}}>G. Graziani | P.IVA 01649180559 - </span>
                 <a href="https://blackandwire.com/policy-privacy" style={{color:"#ffffff"}} target="_black" rel="noopener noreferrer">
                        <span style={{padding:"5px"}}>Policy Privacy</span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default FooterInfo;