import React from 'react';
import { Helmet } from 'react-helmet';

function ActiveHostedForm(props) {
    return (
        <>
            <div className={"width100important _form_"+props.id +" "+props.moreclass} style={{maxWidth:"100% !important"}}></div>
            <Helmet>
                <script src={"https://giorgiagraziani.activehosted.com/f/embed.php?id="+props.id} type="text/javascript" charSet="utf-8"></script>
            </Helmet>
        </>
    );
}

export default ActiveHostedForm;