import React from 'react'
import Menu from './Menu'
import { MenuProvider } from '../items/MenuProvider';

const top="35px";

function Header(props) {
    return(
        props.withMenu === true ?
            <div style={{textAlign:"left"}} className="container-fluid">
                <div className="row headermenu" style={{padding:"15px", top:"0",left:"0",right:"0",position:"fixed",backgroundColor:"white", zIndex:"100"}}>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <a href="/" aria-label="logo">
                            <img src="/static/logo.png" style={{height:"50px", marginTop:"-6px",paddingLeft:"60px"}} alt=""/>
                        </a>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <MenuProvider>
                        <Menu top={top} withMenu={props.withMenu}/>
                    </MenuProvider>
                    </div>
                </div> 
                
            </div>
        :null
    )
}

export default Header;