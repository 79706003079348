import React from 'react';
import Moment from 'react-moment';

function Moments(props) {
    const dateToFormat = new Date(props.data);
    return (
            <Moment fromNow ago date={dateToFormat} />
    );
}

export default Moments;