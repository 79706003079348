import React from 'react';

function QuattorZeroQuattro(props) {
    return (
        <div style={{textAlign:'center',marginTop:"100px"}}>
            404
        </div>
    );
}

export default QuattorZeroQuattro;