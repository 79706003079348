import React,{useState,useEffect} from 'react';



function HeaderCorsi(props) {
    const [tk, setTk] = useState(null)
    const [name, setName] = useState(null)

    function logoutUser(){
        localStorage.removeItem("tk")
        setTk(null)
        window.location.href="/corsi"
    }

    useEffect(() => {
        setTk(localStorage.getItem('tk'))
        setName(localStorage.getItem('name'))
    }, [])
    
    return (
        <div style={{textAlign:"center"}}>
            {name && tk ?
                <div>
                    <a href="/corsi/profile" className="btn-link"><i className="fas fa-user-cog"></i> {name}</a> - <span onClick={()=>{logoutUser()}} style={{marginRight:"15px",cursor:"pointer"}}><i className="fas fa-sign-out-alt"></i> Esci</span>
                </div> :

                    <span style={{marginRight:"15px"}}>
                        <span>Sei già iscritt*?</span>
                        <a href="/corsi/login" style={{marginLeft:"10px"}}>
                            <button className="button-newsletter" style={{color:"white" , backgroundColor:"#2197F3",padding:"5px 15px",fontSize:"13px"}}>ACCEDI</button>
                        </a>
                    </span>
       
            }
        </div>
    );
}

export default HeaderCorsi;