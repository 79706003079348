import React,{useState,useEffect} from 'react'
import './css/newsLetter.css'
import axios from 'axios'
import SocialIcon from '../details/SocialIcons'
import MetaHelmet from '../items/MetaHelmet'
import {PageView, initGA, Event} from '../Tracking'; //https://medium.com/@malith.dev/track-users-in-your-react-app-with-google-analytics-6364ebfcbae8
import SpinnerLoader from '../details/SpinnerLoader'
import ReactPixel from 'react-facebook-pixel';
import FooterInfo from '../details/FooterInfo'
import ActiveHostedForm from '../details/ActiveHostedForm'

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
//https://medium.com/@zoltankohalmy/react-and-django-57f949b0f012

let pathlogo="/static/logo.png";
let categorypage="NEWSLETTER";
let actionsubscription="Iscrizione confermata";
let actionunsubscription="Disiscrizione confermata";
let actionsingin="Richiesta iscrizione inviata";
let actionbutton="Scarica la guida bottone"

function Homepage(props) {
    const [toggle, settoggle] = useState(false)
    const [toggleThanks, settoggleThanks] = useState(false)
    const [toggleGoodBye, settoggleGoodBye] = useState(false)
    const [toggleCheckEmail, settoggleCheckEmail] = useState(false)
    const [email, setEmail] = useState(undefined)
    const [name, setName] = useState(undefined)
    const [lastName, setLastName] = useState(undefined)
    const [error, setError] = useState(undefined)
    const [loading, setLoading] = useState(undefined)
    const [staticpage, setStaticpage] = useState(undefined)
    const [confermack, setConfermack] = useState(false)
    const [showPolicy, setShowPolicy] = useState(false)
    
    useEffect(() => {
        initGA()
        PageView()
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init('704276380318113', advancedMatching, options);
        // if(window.location.pathname==="/subscription-success"){
        //     settoggleThanks(true)
        //     Event(categorypage,actionsubscription,"ISCRIZIONE_CONFERMATA")
        // }else 
        if(window.location.pathname==="/unsubscription-success"){
            settoggleGoodBye(true)
            Event(categorypage,actionunsubscription,"DISISCRIZIONE_CONFERMATA")
        }
        let gurl = "null"
        if(props.match.params.gurl){
            gurl = props.match.params.gurl
        }
        axios.get("/api/v1/emailnewsletter/newsletter/"+gurl).then((res)=>{
            setStaticpage(res.data.data);
            setLoading(true)
        }).catch(error=>{
            console.log(error);
            window.location.href = "/404";
        })
    }, [])

    function saveUser(e){
        e.preventDefault();
        let data={
            "name": name,
            "lastname": lastName,
            "email": email
        }

        axios.post('/api/v1/emailnewsletter/',data).then((res)=>{
            setError(false)
            settoggleCheckEmail(true)
            Event(categorypage,actionsingin,"RICHIESTA_ISCRIZIONE")
        }).catch((err)=>{
            setError(true)
        })
    }
    return(
        <>
            {loading ? 
            <div >
            <MetaHelmet title={staticpage.gtitle} description={staticpage.gdescription} />
            
            <div className="d-block d-md-none" style={{paddingTop:"60px",paddingBottom:"35px"}}> {/* mobile */}
                {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{textAlign:"center", fontSize:"40px",paddingBottom:"20px"}}>
                    <SocialIcon facebook={staticpage.facebook} instagram={staticpage.instagram} pinterest={staticpage.pinterest} youtube={staticpage.youtube} />
                </div> */}
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 justify-content-center align-self-center text-center">
                    <span>
                        <div dangerouslySetInnerHTML={{ __html: staticpage.content}}/>
                        {/* <a href="/newsletter"><button className="button-newsletter" style={{color:staticpage.color_scritta , backgroundColor:staticpage.color}}>{staticpage.testo_bottone}</button></a> */}
                    </span>
                </div>
                {/* <div style={{marginTop:"15px",marginBottom:"15px"}}>
                    {staticpage.file ? 
                        <img src={staticpage.file.split("?")[0]}  style={{width:"100%"}} alt=""/>
                    :
                        null
                    }
                </div> */}
                <FooterInfo isFixed={true}/>
            </div>
            <div className="d-none d-md-block"> {/* desktop */}
                <div className="row" style={{margin:"0"}}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 justify-content-center align-self-center text-center">
                        <span>
                            <div dangerouslySetInnerHTML={{ __html: staticpage.content}} />                            
                            {/* <a href="/newsletter"><button className="button-newsletter" style={{color:staticpage.color_scritta , backgroundColor:staticpage.color}}>{staticpage.testo_bottone}</button></a> */}
                        </span>
                    </div>
                {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" style={{position:"absolute",bottom:"0",textAlign:"center", fontSize:"35px",paddingBottom:"40px"}}>
                    <SocialIcon facebook={staticpage.facebook} instagram={staticpage.instagram} pinterest={staticpage.pinterest} youtube={staticpage.youtube} />
                </div> */}
                {/* {staticpage.file ? 
                    <div className="col-lg-6 col-md-6 col-sm-6 d-none d-sm-block" style={{ backgroundImage: "url(" + staticpage.file.split("?")[0] + ")", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', padding:"0" }}></div>
                :null} */}
                <FooterInfo isFixed={true}/>
                </div>
            </div>

            <div className="inputNewsletter-background" hidden={!toggle}>
            <div className="" style={{textAlign:"center",margin:"20px 0"}}>
                <img src={pathlogo} style={{height:"30px", margin:"0 auto"}} alt=""/>
                    <div style={{ fontWeight:"bold",marginRight:"15px",right:"5px",top:"5px",position:"absolute"}} onClick={()=>settoggle(!toggle)}>
                            X
                        </div>
                    </div>
                <div hidden={!toggle}>
                    <div className="col-lg-4 col-md-6 col-sm-8 col-xs-12 offset-lg-4 offset-md-3 offset-sm-2 contentNewsletter">
                        <ActiveHostedForm id={"5"}></ActiveHostedForm>
                        {/* <div>
                        <form onSubmit={(e)=>{saveUser(e)}}>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6" style={{marginBottom:"15px"}}>
                                    <div className="input-group">
                                        <input required type="text" className="form-control" value={name} onChange={(event)=>{setName(event.target.value)}} placeholder="Nome" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6" style={{marginBottom:"15px"}}>
                                    <div className="input-group">
                                        <input required type="text" className="form-control" value={lastName} onChange={(event)=>{setLastName(event.target.value)}} placeholder="Cognome" />
                                    </div>
                                </div>
                                <div className="col-12" style={{marginBottom:"15px"}}>
                                    <div className="input-group">
                                        <input required type="email" className="form-control" value={email} onChange={(event)=>{setEmail(event.target.value)}} placeholder="Email" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <small style={{textDecoration:"underline", color:"grey",cursor:"pointer"}} onClick={()=>{setShowPolicy(!showPolicy)}}>Policy Privacy</small>
                                    <div hidden={!showPolicy} style={{width:"100%", height:"150px",overflow:"auto",border:"1px solid grey"}}>
                                        <div dangerouslySetInnerHTML={{ __html: staticpage.content_privacy_policy}} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <input type="checkbox" name="confermack" value={confermack} onClick={()=>{setConfermack(!confermack)}} id=""/>
                                    Ho letto la Privacy Policy e acconsento al
                                    trattamento dei miei dati personali.
                                </div>
                                <div className="col-12" style={{marginBottom:"15px", marginTop:"15px"}}>
                                    <button disabled={confermack!==true} className={confermack===true ? "button-newsletter" : "button-newsletter button-newsletter-opacity"} style={{backgroundColor:staticpage.color, color:staticpage.color_scritta,width:"100%"}}>ISCRIVITI</button>
                                </div>
                            </div>
                        </form> 
                        <div className="col-12">
                            {error !== undefined ? 
                            <span style={error === true ? {color:"red"} : {color:staticpage.color}}>
                                {error===true ? "Errore, riprova oppure scrivi a: info@blackandwire.com" :  ""} 
                            </span>
                            :null
                            }
                        </div>
                        </div> */}
                    </div>
                    {/* <div  style={{width:"100%",position:"absolute",bottom:"0",textAlign:"center", fontSize:"35px",margin:"0 auto"}}>
                        <SocialIcon facebook={staticpage.facebook} instagram={staticpage.instagram} pinterest={staticpage.pinterest} youtube={staticpage.youtube} />
                    </div> */}
                </div>
            </div>
            {/* <div className="inputNewsletter-background" hidden={!toggleThanks}>
                <Redirect to="/subscription-success"></Redirect> */}
                {/* <div className="" style={{textAlign:"center",margin:"20px 0"}}>
                    <img src={pathlogo} style={{height:"30px", margin:"0 auto"}} alt=""/>
                    <div style={{ fontWeight:"bold",marginRight:"15px", position:"absolute",right:"5px",top:"5px"}} onClick={()=>settoggleThanks(!toggleThanks)}>
                            X
                    </div>
                </div>
                <div className="container">
                    <div hidden={!toggleThanks}>
                        <div className="row">
                            <div className="col-lg-12 col-md-6 col-sm-8 col-xs-12 offset-lg-4 offset-md-3 offset-sm-2" style={{margin:"0 auto",width:"100%"}}>
                                <div style={{wordWrap:"break-word"}}>
                                    <div dangerouslySetInnerHTML={{ __html: staticpage.content_thanks}} />  

                                </div>
                            </div>
                            {ReactPixel.track('track', 'CompleteRegistration')}
                        </div>
                    </div>
                </div>
            </div> */}

            
            <div className="inputNewsletter-background" hidden={!toggleCheckEmail}>
                <div className="" style={{textAlign:"center",margin:"20px 0"}}>
                    <img src={pathlogo} style={{height:"30px", margin:"0 auto"}} alt=""/>
                        <div style={{ fontWeight:"bold",marginRight:"15px", position:"absolute",right:"5px",top:"5px"}} onClick={()=>settoggleCheckEmail(!toggleCheckEmail)}>
                                X
                        </div>
                </div>
                <div className="container">
                    <div hidden={!toggleCheckEmail}>
                        <div className="row">
                            <div className="col-lg-12 col-md-6 col-sm-8 col-xs-12 offset-lg-4 offset-md-3 offset-sm-2" style={{margin:"0 auto",width:"100%"}}>
                                <div style={{wordWrap:"break-word"}}>
                                    <div dangerouslySetInnerHTML={{ __html: staticpage.content_checkemail}} />  
                                </div>
                            </div>
                            {/* <div className="container"  style={{position:"fixed",bottom:"0",textAlign:"center", fontSize:"35px",margin:"0 auto"}}>
                                <SocialIcon facebook={staticpage.facebook} instagram={staticpage.instagram} pinterest={staticpage.pinterest} youtube={staticpage.youtube} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputNewsletter-background" hidden={!toggleGoodBye}>
                <div className="" style={{textAlign:"center",margin:"20px 0"}}>
                    <img src={pathlogo} style={{height:"30px", margin:"0 auto"}} alt=""/>
                        <div style={{ fontWeight:"bold",marginRight:"15px", position:"absolute",right:"5px",top:"5px"}} onClick={()=>settoggleGoodBye(!toggleGoodBye)}>
                                X
                        </div>
                </div>
                <div className="container">
                    <div hidden={!toggleGoodBye}>
                        <div className="row">
                            <div className="col-lg-12 col-md-6 col-sm-8 col-xs-12 offset-lg-4 offset-md-3 offset-sm-2" style={{margin:"0 auto",width:"100%"}}>
                                <div style={{wordWrap:"break-word"}}>
                                    <div dangerouslySetInnerHTML={{ __html: staticpage.content_goodbye}} />  
                                </div>
                            </div>
                            {/* <div className="container"  style={{position:"fixed",bottom:"0",textAlign:"center", fontSize:"35px",margin:"0 auto"}}>
                                <SocialIcon facebook={staticpage.facebook} instagram={staticpage.instagram} pinterest={staticpage.pinterest} youtube={staticpage.youtube} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : <SpinnerLoader /> 
        }
        </>
    )
}
export default Homepage;