import React from 'react';
import { FloatingMenu } from 'react-floating-button-menu';

function FloatingMenuContatti(props) {
    return (
        <FloatingMenu
            className="floating-button"
            slideSpeed={500}
            direction="up"
            style={{backgroundColor:"transparent"}}
            spacing={8}
            isOpen={props.floatingOpen}
        >
            <div style={{zIndex:999,cursor:"pointer"}}>
                <a style={{color:"inherit"}} href={"mailto:"+props.mail+"?subject="+props.subject}>
                    <img src={props.bottone_mail} alt="contattami" style={{maxWidth:"150px"}} />
                </a>
            </div>
        </FloatingMenu>
    );
}

export default FloatingMenuContatti;