import React, { Component } from 'react';
import './AppNoFooter.css';
import Header from './components/homepage/Header'
import FooterInfo from './components/details/FooterInfo';

class App extends Component {
  // constructor(props){
  //   super(props);
  // }
  render() {
    return (
      <>
        <Header withMenu={this.props.withMenu} />
        <div className="container-fluid"  style={{ textAlign:"left"}}>
          {this.props.children}
        </div>
      </>
    );
  }
}

export default App;