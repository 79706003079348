import React,{useState,useEffect} from 'react'
import './css/newsLetter.css'
import axios from 'axios'
// import SocialIcon from '../details/SocialIcons'
import MetaHelmet from '../items/MetaHelmet'
import {PageView, initGA, Event} from '../../components/Tracking'; //https://medium.com/@malith.dev/track-users-in-your-react-app-with-google-analytics-6364ebfcbae8
import SpinnerLoader from '../details/SpinnerLoader'
import Helmet from 'react-helmet'
import ReactPixel from 'react-facebook-pixel';
import ActiveHostedForm from '../details/ActiveHostedForm';
// import FooterInfo from '../details/FooterInfo'
// import { Redirect } from 'react-router-dom'

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
//https://medium.com/@zoltankohalmy/react-and-django-57f949b0f012

let pathlogo="/static/logo.png";
// let categorypage="NEWSLETTER";
// let actionsubscription="Iscrizione confermata";
// let actionunsubscription="Disiscrizione confermata";
// let actionsingin="Richiesta iscrizione inviata";
// let actionbutton="Scarica la guida bottone"

function Registrazione(props) {
    // const [toggle, settoggle] = useState(false)
    // const [toggleThanks, settoggleThanks] = useState(false)
    // const [toggleGoodBye, settoggleGoodBye] = useState(false)
    const [toggleCheckEmail, settoggleCheckEmail] = useState(false)
    // const [email, setEmail] = useState(undefined)
    // const [name, setName] = useState(undefined)
    // const [lastName, setLastName] = useState(undefined)
    // const [error, setError] = useState(undefined)
    const [nologo, setNologo] = useState(props.nologo)
    const [loading, setLoading] = useState(undefined)
    const [staticpage, setStaticpage] = useState(undefined)
    // const [confermack, setConfermack] = useState(false)
    // const [showPolicy, setShowPolicy] = useState(false)
    const [idCorso, setidCorso] = useState(null)
    
    useEffect(() => {
        initGA()
        PageView()
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init('704276380318113', advancedMatching, options);
        ReactPixel.pageView();
        let gurl = "null"
        if(props.match.params.gurl){
            gurl = props.match.params.gurl
        }
        axios.get("/api/v1/emailnewsletter/newsletter/"+gurl).then((res)=>{
            setStaticpage(res.data.data);
            setidCorso(res.data.data.idCorso)
            setLoading(true)
        }).catch(error=>{
            window.location.href = "/404";
        })
    }, [])

    return(
        <>
            {loading ? 
            <div style={{marginTop:"-30px"}}>
            <MetaHelmet title={staticpage.gtitle} description={staticpage.gdescription} />
            <div className="inputNewsletter-background">
                {staticpage.background_image_url && staticpage.background_image_url_mobile ?
                <>
                    <div className="d-none d-md-block" style={{backgroundImage:"url('"+staticpage.background_image_url+"')",
                        backgroundPosition:"center",backgroundRepeat:"no-repeat", backgroundSize:"cover",bottom:"0",left:"0",position:"fixed",right:"0",top:"0",zIndex:"-100"}}>                
                    </div>
                    <div className="d-block d-md-none" style={{backgroundImage:"url('"+staticpage.background_image_url_mobile+"')",
                        backgroundPosition:"center",backgroundRepeat:"no-repeat", backgroundSize:"cover",bottom:"0",left:"0",position:"fixed",right:"0",top:"0",zIndex:"-100"}}>                
                    </div>
                </>
                :null}
                <div className="" style={{textAlign:"center",margin:"20px 0"}}>
                    {!nologo ? <a href="/"><img src={pathlogo} style={{height:"30px", margin:"0 auto"}} alt=""/></a> : null}
                    
                    <div style={{ fontWeight:"bold",marginRight:"15px", position:"absolute",right:"5px",top:"5px"}}>
                            <a href="/" style={{color:"black",textDecoration:"none"}}>X</a>
                    </div>
                </div>
                <div>
                    <div className="col-lg-5 col-md-8 col-sm-10 col-xs-12 contentNewsletter" style={{margin:"0 auto"}}>
                        <div className="container">
                            <div className="col-12">
                                <div dangerouslySetInnerHTML={{ __html: staticpage.intestazione}}/>
                            </div>
                            <div className="row">
                                {staticpage.left_image_url ? 
                                    <div className="col-lg-6 col-md-6 col-sm-12 cl-xs-12">
                                        <img src={staticpage.left_image_url} style={{width:"100%"}} alt=""/>
                                    </div>
                                :null}
                                <div className="col-lg-6 col-md-6 col-sm-12 cl-xs-12">
                                    <div dangerouslySetInnerHTML={{ __html: staticpage.right_text}}/>
                                </div>
                            </div>
                        </div>
                        {idCorso && staticpage.intestazione.indexOf(".activehosted.com/f/") === -1 ?
                            <>
                                <ActiveHostedForm id={idCorso}></ActiveHostedForm>
                            </>
                        :null}
                        <div style={{padding:"20px"}}>
                            <div dangerouslySetInnerHTML={{ __html: staticpage.recensioni}}/>
                        </div>
                    </div>
                    
                </div>
            </div>            
        </div>
        : <SpinnerLoader /> 
        }
        </>
    )
}
export default Registrazione;