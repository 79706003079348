import React,{useState,useEffect} from 'react';
import axios from 'axios'
import { SpinnerLoaderCorso } from '../details/SpinnerLoader';

import { Modal,ModalBody,ModalHeader } from 'reactstrap'
import ActiveHostedForm from '../details/ActiveHostedForm';

function BottoneCorsi(props) {
    const [stato, setStato] = useState(null)
    const [checkingPaid, setCheckingPaid] = useState(false)
    const [hiddenbutton, setHiddenbutton] = useState(false)
    const [messaggio, setMessaggio] = useState("Rinnova il tuo abbonamento prima di procedere")
    const [mioCorso, setMioCorso] = useState(null)
    const [show, setShow] = useState(false)
    const [selectedCorso, setSelectedCorso] = useState(null);

    useEffect(() => {
        if(props.corso && props.listofMyCourse){
            checkIfCorsoAttivo().then((res)=>{
                setStato(res)
            })
        }
    }, [props.corso && props.listofMyCourse])

    async function checkIfCorsoAttivo(){
        let crs = props.listofMyCourse.find(x=>x.corso === props.corso.id);
        //ACTIVE -> attivo
        //NOT_ACTIVE -> non attivo
        //ACTIVE_NOT_RENEWED -> rinnovare la sottoscrizione
        let result = "ERROR"
        if(!crs){
            return "NOT_ACTIVE";
        }        
        if(crs.active && !crs.isSubscription){
            result = "ACTIVE"
        }
        if(!crs.active){
            result = "NOT_ACTIVE";
        }
        if(crs.isSubscription && crs.active){
            //controllare che sia nel mese oppure che le rate mancanti siano <= 0
            if(crs.rateMancanti<=0 || (crs.nextRata && new Date(crs.nextRata) > new Date())){
                result = "ACTIVE";
            }else{
                //qui effettuare chiamata per capire se la rata del corso è stata pagata, e nel caso aggiornare al prossimo pagamento
                //fare chiamata al mio sistema che includerà le chiamate a paypal
                let data = {
                    token:localStorage.getItem("tk"),
                    corso:crs
                }
                await axios.put("/api/v1/corsi/listofmycourse/",data).then((res)=>{
                    if(res.data.data === "ACTIVE"){
                        result = "ACTIVE"    
                    }else{
                        result = "ACTIVE_NOT_RENEWED"
                    }
                })
            }
        }
        return result
    }
    
    function checkIfPaid(){
        setCheckingPaid(true)
        setTimeout(() => {
            setCheckingPaid(false)
            setMessaggio("Impossibile validare il pagamento, contattami su info@blackandwire.com se hai già effettuato il pagamento")
            setHiddenbutton(true)
        }, 2000);
    }

    function toggle(){
        if(!show){
            setSelectedCorso(null);
        }
        setShow(!show)
    }

    function handleShow(e,corso){
        e.preventDefault();
        setSelectedCorso(corso)
        setShow(true)
    }

    return (
        stato && !checkingPaid ?
            <div style={{textAlign:'center'}}>
                {stato === "ACTIVE" ?
                    <div className="" style={{textAlign:"center",marginTop:"30px"}}> 
                            <a href={"/corsi/"+props.corso.gurl}>
                                <button className="button-newsletter" style={{color:"white" , backgroundColor:props.corso.color,padding:"10px 40px"}}>ACCEDI</button>
                            </a>
                    </div>
                :
                stato ==="NOT_ACTIVE" ? 
                    (new Date() >= new Date(props.corso.validity_from) && new Date() < new Date(props.corso.validity_to))  ?
                        <div style={{textAlign:"center",marginTop:"30px"}}>
                            <a href={"/corsi/"+props.corso.gurl+"/salepage"}>
                                <button className="button-newsletter" style={{margin:"0 auto",color:"white" , backgroundColor:"#053861",padding:"10px 40px",fontSize:"15px"}}>
                                    SCOPRI DI PIÙ
                                </button>
                            </a>
                        </div>
                    :
                    <div>
                       <div style={{textAlign:"center",marginTop:"30px"}}>
                            <button className="button-newsletter" onClick={(e)=>handleShow(e, props.corso)} style={{margin:"0 auto",color:"white" , backgroundColor:"#053861",padding:"10px 40px",fontSize:"15px"}}>
                            SCOPRI DI PIÙ
                            </button>
                        </div>
                    </div>
                : 
                stato ==="ACTIVE_NOT_RENEWED" ? 
                    <div style={{textAlign:"center",padding:"25px"}}>
                        <div className="boxrinnovaabbonamento">
                            <div>{messaggio}</div>
                            <div hidden={hiddenbutton}><button onClick={()=>checkIfPaid()}>Clicca qui</button></div>
                        </div>
                    </div>
                :
                <SpinnerLoaderCorso color={props.corso.ButtonColor} />
                }
                {selectedCorso && show ? 
                    <Modal isOpen={show} toggle={toggle} centered>
                        <ModalHeader toggle={toggle}>{selectedCorso.title}</ModalHeader>
                        {selectedCorso.show_minicorso_text ?
                            <ModalBody style={{paddingTop:"0px"}}>
                               <div dangerouslySetInnerHTML={{ __html: selectedCorso.message_minicorso}}></div>
                            </ModalBody>
                        :selectedCorso.id_form_waiting_list ? 
                            <ModalBody style={{paddingTop:"0px"}}>
                                <ActiveHostedForm id={selectedCorso.id_form_waiting_list}></ActiveHostedForm>
                            </ModalBody>
                        :
                        <div style={{padding:"15px"}}>
                            <p style={{textAlign:"center"}}><span style={{fontSize:"18px"}}>Le iscrizioni al corso sono chiuse in questo momento.</span></p>
                            <p style={{textAlign:"center"}}><span style={{fontSize:"18px"}}>
                                <strong>per qualsiasi informazione scrivi a <a style={{color:"inherit"}} href="mailto:info@blackandwire.com">info@blackandwire.com</a></strong></span>
                            </p>
                        </div>
                        }
                    </Modal>
                :null}
            </div>
        :<SpinnerLoaderCorso color={props.corso.ButtonColor} />
    );
}

export default BottoneCorsi;