import React from 'react';
import { Page, Text, View, Document, StyleSheet,pdf, Font } from '@react-pdf/renderer';
import moment from 'moment'

const styles = StyleSheet.create({
  page: {
    margin:50
  },
  section:{
    color:'black',
    textAlign: 'left', 
    fontSize:10,
    marginBottom:30,
    padding: 10,
  },
  section2:{
    color:'black',
    textAlign: 'left', 
    fontSize:10,
    padding: 10,
    flexDirection: 'row',
  },
  text1:{
      width:350,
      fontFamily:"Oswald"
  },
  text2:{
    fontFamily:"Oswald"
},
  text_dati_left:{
    marginTop:5,
  },
  text_dati_right:{
    marginTop:5,
  },
  text_dati_left_2:{
    marginTop:5,
    width:50,
    textAlign:"left",
  },
  text_dati_right_2:{
    marginTop:5,
    width:60,
    textAlign:'right',
  },
  section3:{
    marginTop:5,
    color:'black',
    textAlign: 'left', 
    fontSize:10,
    flexDirection: 'row',
  },
  section4:{
    marginTop:5,
    color:'black',
    textAlign: 'left', 
    fontSize:10,
    padding:10,
    flexDirection: 'row',
    width:400,
    
  },
  line:{
      backgroundColor:"black",
      height:1,
      marginLeft:10,
      width:460
  },
  text4:{
    backgroundColor:"#0062cc",
    color:"white",
    padding:2,
    width:250
  },
  text4_2:{
    backgroundColor:"#0062cc",
    textAlign:'right',
    color:"white",
    width:210,
    padding:2,
  },
  text4_right_2:{
    marginTop:5,
    textAlign:'right',
    width:210,
    padding:2,
  },
  boxbottom:{
    marginTop:50,
    textAlign:'center',
    width:460,
    fontSize:10,
  },
  textbottom:{
    marginTop:10,
    fontSize:10,
    fontFamily:"Oswald"
  }

});
Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

// Create Document Component
export function PrecompilatoFattura(props){
    return (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={{padding:2}}>Giorgia Graziani</Text>
        <Text style={{padding:2}}>Via di Loreto 21 05018 Orvieto, Italia</Text>
        <Text style={{padding:2}}>P.I. 01649180559</Text>
        <Text style={{padding:2}}>C.F. GRZGRG90P69G148Z</Text>
      </View>
      <View style={styles.section2}>
            <View>
                <Text style={styles.text1}>Dati cliente</Text>
                <Text style={styles.text_dati_left}>{props.payment.name + " "+props.payment.lastname}</Text>
                <Text style={styles.text_dati_left}>{props.payment.city+" "+props.payment.zipcode+" ("+props.payment.province+")"+" - "+props.payment.address+" - "+ props.payment.country}</Text>
                <Text style={styles.text_dati_left}>{props.payment.vat_number}</Text>
                <Text style={styles.text_dati_left}>Metodo di pagamento: {props.payment.method_payment}</Text>
            </View>
            <View>
                <Text style={styles.text2}>Fattura</Text>
                <View style={styles.section3}>
                    <Text style={styles.text_dati_left_2}>N°</Text>
                    <Text style={styles.text_dati_right_2}>{props.payment.num_fattura}</Text>
                </View>
                <View style={styles.section3}>
                    <Text style={styles.text_dati_left_2}>Data</Text>
                    <Text style={styles.text_dati_right_2}>{moment(props.payment.timestamp).format('DD/MM/YYYY')}</Text>
                </View>
            </View>
      </View>
      <View style={styles.section4}>
            <View>
                <Text style={styles.text4}>Descrizione</Text>
                {Number(props.payment.paid)>77.47 ? 
                <>
                <Text style={styles.text_dati_left}>Corso "{props.payment.corso_name}"</Text>
                <Text style={styles.text_dati_left}>Marca da bollo</Text>
                </>
                :<>
                    <Text style={styles.text_dati_left}>Corso "{props.payment.corso_name}"</Text>
                </>}
            </View>
            <View>
                <Text style={styles.text4_2}>EURO</Text>
                {Number(props.payment.paid)>77.47 ? 
                <>
                    <Text style={styles.text4_right_2}>{(Number(props.payment.paid)-2).toFixed(2)} €</Text>
                    <Text style={styles.text4_right_2}>{(2).toFixed(2)} €</Text>
                </>
                :<>
                    <Text style={styles.text4_right_2}>{Number(props.payment.paid).toFixed(2)} €</Text>
                </>}
            </View>
      </View>
      <View style={styles.line}></View>
      <View style={styles.section2}>
            <View>
                <Text style={styles.text1}></Text>
            </View>
            <View>
                <View style={styles.section3}>
                    <Text style={styles.text_dati_left_2}>Tot.</Text>
                    <Text style={styles.text_dati_right_2}>{Number(props.payment.paid).toFixed(2)} €</Text>
                </View>
            </View>
      </View>
      <View style={styles.boxbottom}>
            <Text>Operazione effettuata ai sensi dell’articolo 1, commi da 54 a 89, della Legge n.</Text>
            <Text>190/2014 così come modificato dalla Legge numero 208/2015 e dalla</Text>
            <Text>Legge 145/2018. Si richiede la non applicazione della ritenuta alla fonte a</Text>
            <Text>titolo d’acconto ai sensi Dell’articolo 1 comma 67 della Legge numero 190/2014.</Text>
            {Number(props.payment.paid)>77.47 ?
                <Text style={styles.textbottom}>Imposta di bollo da 2 euro assolta sull’originale ID: {props.payment.codice_marca}</Text>
            :null}
      </View>
    </Page>
  </Document>
)};

const saveBlob = (blob, filename) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  
  export const savePdf = async (document, filename) => {
    saveBlob(await pdf(document).toBlob(), filename);
  };