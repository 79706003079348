import React,{useEffect,useState} from 'react';
import axios from 'axios'
import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';

let pathlogo="/static/logo.png";

function ThankyouPage(props) {
    const [staticpage, setStaticpage] = useState(undefined)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init('704276380318113', advancedMatching, options);
        ReactPixel.pageView();
        let gurl = "null"
        if(props.match.params.gurl){
            gurl = props.match.params.gurl
        }
        axios.get("/api/v1/emailnewsletter/newsletter/"+gurl).then((res)=>{
            setStaticpage(res.data.data);
            setLoading(true)
        }).catch(error=>{
            window.location.href = "/404";
        })
    }, [])
    return (
        <div className="container">
            {loading ? 

            <div className="row" style={{marginTop:"100px"}}>
                <div className="col-lg-12 col-md-6 col-sm-8 col-xs-12 offset-lg-4 offset-md-3 offset-sm-2" style={{margin:"0 auto"}}>
                    <div style={{wordWrap:"break-word"}}>
                        <div dangerouslySetInnerHTML={{ __html: staticpage.content_thanks}} />
                    </div>
                </div>
            </div>
            
                   
            :null}
        </div>
    );
}

export default ThankyouPage;