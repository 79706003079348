import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router , Route,Switch} from 'react-router-dom'
import './index.css';
import './countdown.css';
import App from './App';
import AppWithNoFooter from './AppWithNoFooter'
import * as serviceWorker from './serviceWorker';
import InfoPage from './components/homepage/infopage';
import Blog from './components/homepage/Blog';
import BlogPost from './components/details/Blogdetails';
import Homepage from './components/homepage/HomePage';
import ChiSono from './components/homepage/ChiSono';
import HomepageInstagram from './components/homepage/HomepageInstagram';
import Corso from './components/homepage/Corso';
import Subscribe from './components/homepage/Subscribe';
import SubscribeContactEmail from './components/homepage/SubscribeContactEmail';
import PaymentPage from './components/homepage/PaymentPage';
import ChangePassword from './components/homepage/ChangePassword';
import ListaCorsi from './components/homepage/ListaCorsi';
import ThankyouPage from './components/homepage/ThankyouPage';
import Login from './components/homepage/Login';
import Registrazione from './components/homepage/Registrazione';
import ThankyouPay from './components/homepage/ThankyouPay';
import Profile from './components/homepage/Profile';
import QuattorZeroQuattro from './components/homepage/QuattorZeroQuattro';
import FattureTest from './components/FattureTest';
import ConsulenzeCloseLP from './components/homepage/ConsulenzeCloseLP';
import Minicorso from './components/homepage/Minicorso';
import RedirectPage from './components/homepage/RedirectPage';

ReactDOM.render(
    <Router>
        <Switch>
            <Route exact path={"/"} render={(props) => <AppWithNoFooter withMenu={true}><Homepage {...props}/></AppWithNoFooter>} />
            <Route exact path={"/consulenze"} render={(props) => <AppWithNoFooter withMenu={true}><ConsulenzeCloseLP {...props}/></AppWithNoFooter>} />
            <Route exact path={"/homepreview/:gurl"} render={(props) => <AppWithNoFooter withMenu={true}><Homepage {...props}/></AppWithNoFooter>} />
            <Route  path={"/dashboard/4c7daae6-6868-11eb-ae93-0242ac130002"} render={(props) => <><FattureTest {...props}/></>} />
            <Route  path={"/instagram/:gurl"} render={(props) => <AppWithNoFooter withMenu={true}><HomepageInstagram {...props}/></AppWithNoFooter>} />
            <Route  path={"/instagram"} render={(props) => <AppWithNoFooter withMenu={true}><HomepageInstagram {...props}/></AppWithNoFooter>} />
            <Route exact path={"/newsletter/:gurl"} render={(props) => <AppWithNoFooter withMenu={true}><Registrazione nologo={true} {...props}/></AppWithNoFooter>} />
            <Route exact path={"/newsletter"} render={(props) => <AppWithNoFooter withMenu={true}><Registrazione nologo={true} {...props}/></AppWithNoFooter>} />
            <Route exact path={"/subscription-success/:gurl"} render={(props) => <AppWithNoFooter withMenu={true}><ThankyouPage {...props}/></AppWithNoFooter>} />
            <Route exact path={"/subscription-success"} render={(props) => <AppWithNoFooter withMenu={true}><ThankyouPage {...props}/></AppWithNoFooter>} />
            <Route exact path={"/unsubscription-success/:gurl"} render={(props) => <AppWithNoFooter withMenu={true}><Homepage {...props}/></AppWithNoFooter>} />
            <Route exact path={"/unsubscription-success"} render={(props) => <AppWithNoFooter withMenu={true}><Homepage {...props}/></AppWithNoFooter>} />
            <Route exact path={"/blog"} render={(props) => <App withMenu={true}><Blog {...props}/></App>} />
            <Route path={"/blog/:gurl"} render={(props) => <App withMenu={true}><BlogPost {...props}/></App>} />
            <Route path={"/tag/:gurl"} render={(props) => <App withMenu={true}><BlogPost {...props}/></App>} />
            <Route exact path={"/corsi"} render={(props) => <App withMenu={true}><ListaCorsi {...props}/></App>} />
            <Route exact path={"/corsi/login"} render={(props) => <App withMenu={true}><Login {...props}/></App>} />
            <Route exact path={"/corsi/profile"} render={(props) => <App withMenu={true}><Profile {...props}/></App>} />
            <Route exact path={"/corsi/changepassword/:id_password"} render={(props) => <App withMenu={true}><ChangePassword {...props}/></App>} />
            <Route exact path={"/corsi/:slugcorso"} render={(props) => <App withMenu={true}><Corso {...props}/></App>} />
            <Route exact path={"/corsi/:slugcorso/salepage"} render={(props) => <App withMenu={false}><Subscribe {...props}/></App>} />
            <Route exact path={"/corsi/:slugcorso/salepage/:nome"} render={(props) => <App withMenu={false}><Subscribe {...props}/></App>} />
            {/* <Route exact path={"/corsi/:slugcorso/salepage/p/:contactId"} render={(props) => <App withMenu={false}><SubscribeContactEmail {...props}/></App>} /> */}
            <Route exact path={"/corsi/:slugcorso/pay"} render={(props) => <App withMenu={false}><PaymentPage {...props}/></App>} />
            <Route exact path={"/corsi/:slugcorso/complete-payment"} render={(props) => <App withMenu={false}><ThankyouPay {...props}/></App>} />
            <Route exact path={"/evento/:slugcorso"} render={(props) => <App withMenu={true}><Minicorso {...props}/></App>} />
            <Route path={"/chi-sono"} render={(props) => <App withMenu={true}><ChiSono {...props}/></App>} />
            <Route exact path={"/404"} render={(props) => <AppWithNoFooter withMenu={true}><QuattorZeroQuattro {...props}/></AppWithNoFooter>} />
            <Route path={"/r/:code"} render={(props) => <RedirectPage {...props}/>} />
            <Route path={"/:typepage"} render={(props) => <App withMenu={true}><InfoPage {...props}/></App>} />
        </Switch>
    </Router>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
