import ReactGA from "react-ga4";

let trackingID = "G-FP9RW772GE";

export const initGA = () => {
    ReactGA.initialize(trackingID); 
 }

export const PageView = () => { 
    ReactGA.send({ hitType: "pageview", page: window.location.pathname +  window.location.search });
}

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */

export const Event = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };