import React, { useState, useEffect } from 'react';
import axios from 'axios'
import ReactPixel from 'react-facebook-pixel';
import Helmet from 'react-helmet'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from '../Payment/CheckoutForm'
import { PayPalButton } from "react-paypal-button-v2";
import { Redirect } from "react-router-dom";
import '../Payment/payment.css'
import {Typeahead} from 'react-bootstrap-typeahead'
import {getProvincieLista,getComuniByProvincia} from '../utils/provincie_comuni'

// const stripePromise = loadStripe("pk_test_1lFouh333dVGsmQXwy0xFKfp00HLTVJz4M"); //test
// const clientPaypal = "ARA8jWD1eyOKtTm3sVFCQ_RH3-bgELezmt2yrGcJHpq5bNYbeDmgJ5D4Uqcb7Mi9SYqOr9r6YCqKmu1k" //TEST
const stripePromise = loadStripe("pk_live_CRTsu6exPxayqD11hKQsmfHg00DR2zvOui"); //PRODUZIONE
const clientPaypal = "ARmEW7Q4hyySpontetTS42NGKr_m9Vp_pWr5ZnzaYd4J_VWja8x41XGFbpZ0l44DnCVLQUYTxvDaqhda" //PRODUZIONE
//quindi?

let recenzioni = [
  {
    name: "Monica Miselli",
    position: "0",
    recenzione:
      '<p><span style="color:rgb(0,0,0);font-size:13px;">Grazie Giorgia, perch&eacute; <strong>senza questo meraviglioso corso, <u>non avrei mai potuto imparare questa tecnica</u> cos&igrave; affascinante, per la quale per&ograve; non ci si pu&ograve; improvvisare.</strong> Non sarei mai riuscita ad impararla da sola. Sono troppe le cose da sapere: come usare il cannello, l&rsquo;archetto, come smerigliare&hellip; capire le propriet&agrave; dei metalli. <strong>Ma con le tue spiegazioni chiare e precise sono riuscita in pochissimo tempo a creare gioielli.</strong> Grazie sei fantastica.</span></p>',
    image: "https://www.w3schools.com/howto/img_avatar2.png",
  },
  {
    name: "Marta Alessandrin",
    position: "1",
    recenzione:
      '<p><span style="color:rgb(0,0,0);font-size:13px;">Il &nbsp;corso ISC mi &egrave; capitato un po&apos; per caso ma ha risvegliato un desiderio che avevo tanti anni fa: avvicinarmi al mondo dei gioielli e imparare delle tecniche. Devo dire che il modo in cui &egrave; strutturato il corso &egrave; tra i punti di forza, perch&eacute; <strong>assolutamente accessibile in base al proprio tempo</strong> , le lezioni sono brevi ma molto chiare e le si pu&ograve; rivedere quanto si vuole, inoltre &egrave; accessibile anche economicamente . Ammiro molto la metodicit&agrave; e la precisione di Giorgia nel curare il proprio lavoro, &egrave; molto disponibile e di supporto, e lei stessa in continua crescita ( cosa che reputo molto positiva).&nbsp;</span><span style="color:rgb(0,0,0);font-size:13px;"><br></span></p>',
    image: "https://www.w3schools.com/howto/img_avatar2.png",
  },
  {
    name: "Cristina",
    position: "2",
    recenzione:
      '<p><span style="font-size:14px;">sono felice di frequentare il corso di Giorgia ISC perch&eacute; <strong>&egrave; proprio quello di cui avevo bisogno</strong>: informazioni, dritte e insegnamenti chiari, precisi e utili. Mi piace che i video siano abbastanza corti con le informazioni necessarie per realizzare una specifica cosa, da cui partire per mille altre idee. <strong>Per chi come me ha poco tempo</strong>, poter vedere questi video quando pi&ugrave; preferisco &egrave; ottimo. <strong>Lo consiglio!</strong></span></p>',
    image: "https://www.w3schools.com/howto/img_avatar2.png",
  },{
    name: "Elisa",
    position: "3",
    recenzione:
      '<p><span style="font-size:14px;">Mi sono approcciata alla saldatura da autodidatta... Non riuscendo a capire dove sbagliavo. Poi per fortuna ho incontrato Giorgia e il suo corso sulla saldatura. <strong>Soldi assolutamente ben spesi</strong>. <strong>A</strong><strong>vere le lezioni sempre a disposizione e sempre aggiornate fa sicuramente la differenza</strong>! Mi sento assolutamente di ringraziarla per il suo impegno e per il suo <strong>metodo semplice e intuitivo ma assolutamente efficace</strong>.</span></p>',
    image: "https://www.w3schools.com/howto/img_avatar2.png",
  },{
    name: "Rosaria - Cucicapricci",
    position: "4",
    recenzione:
      '<p><span style="font-size:14px;">Prima di decidere di partecipare al corso ISC di Giorgia<strong>&nbsp;ho speso tanto solo per fare tanti tentativi per lavoro apparentemente facili</strong> sui videotutorial. Alla fine me ne uscivo sempre demoralizzata perch&eacute; spesso i risultati non erano quelli che mi aspettavo. Seguivo gi&agrave; Giorgia e quando ho appreso che avrebbe fatto un corso cucito addosso a chi naviga in mare aperto ho deciso di tentare. La sua disponibilit&agrave; e la sua competenza non mi ha fatto avere dubbi, cos&igrave; ho iniziato questo percorso con la consapevolezza di avere un punto di riferimento...qualcuno a cui poter chiedere un suggerimento o un consiglio..<strong>&nbsp;Ora i miei momenti di stallo solo pi&ugrave; rari e la mia creativit&agrave; si &egrave; arricchita</strong>... ora mi invento soluzioni diverse. <strong>ISC &egrave; stato un trampolino per esperienze creative nuove!</strong></span></p>',
    image: "https://www.w3schools.com/howto/img_avatar2.png",
  },
];

function PaymentPage(props) {
    const [salepages, setSalepages] = useState(null)
    const [page, setSalepage] = useState(null)
    const [thankyoupage, setThankyoupage] = useState(false)
    const [saving, setSaving] = useState(false)
    const [setAccetto, setSetAccetto] = useState(false)
    const [mail, setMail] = useState("")
    const [email, setEmail] = useState("")
    const [nome, setNome] = useState("")
    const [cognome, setCognome] = useState("")
    const [codiceFiscalePiva, setCodiceFiscalePiva] = useState("")
    const [indirizzo, setIndirizzo] = useState("")
    const [provincia, setProvincia] = useState("")
    const [citta, setCitta] = useState("")
    const [paese, setPaese] = useState("Italia")
    const [codicePostale, setCodicePostale] = useState("")
    const [prezzo, setPrezzo] = useState("")
    const [prezzoSconto, setPrezzoSconto] = useState(0)
    const [codediscount, setCodediscount] = useState(null)
    const [prezzoBarrato, setPrezzoBarrato] = useState("")
    const [password, setPassword] = useState("")
    const [repassword, setRepassword] = useState("")
    const [isOkBuono, setIsOkBuono] = useState(null)
    const [accepted, setAccepted] = useState(false)
    const [message, setMessage] = useState(null)
    const [selectMethod, setSelectMethod] = useState("stripe")
    const [verificaCodice, setVerificaCodice] = useState(false)
    const [loading, setLoading] = useState(true)
    const [foundUser, setFoundUser] = useState(false)
    const [giaIscritto, setGiaIscritto] = useState(false)
    const [isSubscription, setIsSubscription] = useState(false)
    const [isCodiceFiscale, setIsCodiceFiscale] = useState(true)

    useEffect(() => {
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init('704276380318113', advancedMatching, options);

        let slugcorso=props.match.params.slugcorso
        if(!props.match.params.slugcorso){
            slugcorso="progetto-gioielli"
        }
        axios.get("/api/v1/salepage/"+slugcorso+"/").then((res)=>{
            setSalepage(res.data)
            setSalepages(res.data.salespageitem_set)
            setMail("<a style='text-decoration:underline;' href='mailto:"+res.data.email+"'>"+res.data.email+"</a>")
            setPrezzo("<span>"+(res.data.corso.price ? Number(res.data.corso.price).toFixed(0) + "€" : "")+"</span>")
            setPrezzoBarrato("<span style='text-decoration: line-through'>"+(res.data.corso.strikethrough_price ? Number(res.data.corso.strikethrough_price).toFixed(0) + "€" : "")+"</span>")
        })
    }, [])
    useEffect(() => {
        if(paese.toLocaleLowerCase() !=="italia"){
            setCitta("")
            setProvincia("")
            setCodicePostale("")
        }
    }, [paese])

    useEffect(() => {
        if(page){
            let data = {
                token:localStorage.getItem("tk"),
                type:"basedatauser"
            }
            axios.post("/api/v1/corsi/listofmycourse/",data).then((res)=>{
                if(res.data.corso_list.find(x=>x.id === page.corso.id).lenght >= 0){
                    setGiaIscritto(true)
                }
                if(res.data.userdata){
                    setCitta(res.data.userdata.city)
                    setIndirizzo(res.data.userdata.address)
                    setProvincia(res.data.userdata.province)
                    setCognome(res.data.userdata.lastname)
                    setNome(res.data.userdata.name)
                    setEmail(res.data.userdata.email)
                    setCodicePostale(res.data.userdata.zipcode)
                    setPaese(res.data.userdata.country)
                    setCodiceFiscalePiva(res.data.userdata.vat_number)                    
                }
                setLoading(false)
                setFoundUser(true)
                
            }).catch(error=>{
                setGiaIscritto(false)
                setLoading(false)
                setFoundUser(false)
            })
        }
    }, [page])

    function setChangeCodFiscale(e,valore){
        e.preventDefault();
        setIsCodiceFiscale(valore);
    }

    function impostaProvincia(provinciaSelezionata){
        if(provinciaSelezionata.length > 0){
            setProvincia(provinciaSelezionata[0].code)
        }else{
            setProvincia("")
            setCitta("")
            setCodicePostale("")
        }
    }
    function impostaComune(comuneSelezionato){
        if(comuneSelezionato.length > 0){
            setCitta(comuneSelezionato[0].nome)
            setCodicePostale(comuneSelezionato[0].cap)
        }else{
            setCitta("")
            setCodicePostale("")
        }
    }

    function invia(e){
        e.preventDefault();
        if(!accepted){
            if(!foundUser && password!==repassword){
                setMessage("Le password non coincidono o utente già presente")
            }else{
                setAccepted(!accepted);
                setMessage("")
            }
        }else{
            setAccepted(!accepted)
        }
    }
    function setMessagePaing(valore){
        if(valore){
            setMessage(valore)
        }else{
            setMessage("Stiamo elaborando la tua richiesta, attendi un attimo....")
        }
        window.scrollTo(0, 2000)
    }

    function checkCodeDiscount(){
        if (codediscount){
            let data = {codediscount:codediscount,corso_id:page.corso.id}
            axios.post('/api/v1/codediscountcheck/',data).then((res)=>{
                setPrezzoSconto(res.data.price_sale)
                setIsOkBuono(true)
            }).catch(error=>{
                setIsOkBuono(false)
                setPrezzoSconto(0)
            })
        }
    }

    function saveAccountForFree(){
        setSaving(true)
        let data={
            transaction_id:"no_transaction_id",
            method_payment:"free",
            isSubscription:false,
            subscription_id:null,
            email:email,
            name:nome,
            lastname:cognome,
            password:password,
            address:indirizzo,
            city:citta,
            country:paese,
            province:provincia,
            zipcode:codicePostale,
            vat_number:codiceFiscalePiva,
            gurl:page.corso.gurl,
            codediscount:codediscount,
            price_sale:prezzoSconto,
            validdiscount:isOkBuono
        }
        axios.post('/api/v1/'+page.corso.id+'/user/',data).then((res)=>{
            if(res.data.error){
                setSaving(false)
                setMessage(res.data.msg.replace("[[[EMAIL]]]",page.email))
            }else{
                setSaving(false)
                setThankyoupage(true)
            }
        })
    }

    function saveAccount(transaction_id,status,method,isSubscription,subscription_id){ //inserire la condizione per pagare con bonifico
        if(status==="succeeded"){ //mettere i possibili status che ritornano
            setSaving(true)
            let data={
                transaction_id:transaction_id,
                isSubscription:isSubscription,
                subscription_id:subscription_id,
                method_payment:method,
                status:status,
                email:email,
                name:nome,
                lastname:cognome,
                password:password,
                address:indirizzo,
                city:citta,
                country:paese,
                province:provincia,
                zipcode:codicePostale,
                vat_number:codiceFiscalePiva,
                gurl:page.corso.gurl,
                codediscount:codediscount,
                price_sale:prezzoSconto,
                validdiscount:isOkBuono
            }
            axios.post('/api/v1/'+page.corso.id+'/user/',data).then((res)=>{
                if(res.data.error){
                    setSaving(false)
                    setMessage(res.data.msg.replace("[[[EMAIL]]]",page.email))
                }else{
                    setSaving(false)
                    setThankyoupage(true)
                }
            })
        }else{
            setMessage("Qualcosa è andato storto, contatta [[[EMAIL]]] per maggiori informazioni".replace("[[[EMAIL]]]",page.email))
        }
    }

    return (
        <div style={{marginTop:"30px"}}>
            <Helmet><script src="https://js.stripe.com/v3/"></script></Helmet>
            {page? 
                thankyoupage===false ?
                <div>
                <Elements stripe={stripePromise}>
                    <div className="input-dati container" style={{paddingBottom:"150px"}}> 
                    <div className="row">
                        <div className="col-lg-7 col-md-9 col-sm-12 col-xs-12" style={{margin:"0 auto"}}>
                            <div className="container paymentinfotitle" style={{color:page.corso.color}}>
                                <h1 className={"corso-titolo"}>{page.corso.title}</h1>
                                {/* <div className="container">
                                    <div className="col-lg-7 col-md-9 col-sm-12 col-xs-12" style={{margin:"0 auto"}} dangerouslySetInnerHTML={{ __html: page.corso.subtitle}}/>
                                </div> */}
                                <div className="col-lg-7 col-md-9 col-sm-12 col-xs-12" style={{margin:"0 auto",textAlign:"center",color:page.corso.color,fontWeight:"bold",fontSize:"18px"}} 
                                dangerouslySetInnerHTML ={{__html:page.message_price
                                    .replace("[[[PREZZO_BARRATO]]]",prezzoBarrato)
                                    .replace("[[[PREZZO]]]",prezzo)
                                    }}/>
                                <div className="container">
                                    <div className="col-lg-7 col-md-9 col-sm-12 col-xs-12" style={{margin:"0 auto"}} dangerouslySetInnerHTML={{ __html: page.message_refund}}/> 
                                </div>
                            </div>
                            
                            {!loading ? 
                                !giaIscritto ?
                                <>
                                <div className='paymentinfodiv' style={{marginTop:"15px",padding:"10px"}}>
                                    <form onSubmit={(e)=>{invia(e)}} >
                                        <div><b style={{color:page.corso.color}}>CREA IL TUO ACCOUNT</b></div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Nome*</label>
                                                    <input disabled={foundUser} autoComplete="nope" required className="form-control" value={nome} onChange={(e)=>setNome(e.target.value)}  placeholder="Inserisci il nome"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Cognome*</label>
                                                    <input required disabled={foundUser} autoComplete="nope" className="form-control" value={cognome} onChange={(e)=>setCognome(e.target.value)}  placeholder="Inserisci il cognome"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Email*</label>
                                                    <input required type="email" disabled={foundUser} autoComplete="nope" className="form-control" value={email} onChange={(e)=>setEmail(e.target.value)}  placeholder="Inserisci email"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>
                                        </div>
                                        <div hidden={foundUser} className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Password*</label>
                                                    <input required={!foundUser} type="password" autoComplete="nope" className="form-control" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Inserisci pasword"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Ripeti password*</label>
                                                    <input required={!foundUser} type="password" autoComplete="nope"  className="form-control" value={repassword} onChange={(e)=>setRepassword(e.target.value)} placeholder="Ripeti pasword"/>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div>
                                            <b style={{color:page.corso.color}}>DATI NECESSARI PER LA FATTURA</b>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Paese*</label>
                                                    <input required className="form-control" autoComplete="nope" disabled={foundUser} value={paese} onChange={(e)=>setPaese(e.target.value)}  placeholder="Inserisci Stato"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    {paese.toLowerCase() !== "italia" ? 
                                                    <>
                                                        <label>Provincia*</label>
                                                        <input required className="form-control" disabled={foundUser} value={provincia} onChange={(e)=>setProvincia(e.target.value)}  placeholder="Prov."/>
                                                    </>
                                                    :
                                                    <>
                                                        <label>Provincia*</label>
                                                        <Typeahead
                                                            disabled={foundUser}
                                                            defaultInputValue={provincia}
                                                            autocomplete="nope"
                                                            required
                                                            id="basic-typeahead-single2"
                                                            labelKey="name"
                                                            onChange={impostaProvincia}
                                                            options={getProvincieLista()}
                                                            placeholder=""
                                                        />
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                {paese.toLowerCase() !== "italia" ? 
                                                    <>
                                                    <label>Comune*</label>
                                                    <input required className="form-control" disabled={foundUser} value={citta} onChange={(e)=>setCitta(e.target.value)}  placeholder="Inserisci Citta"/>
                                                    </>
                                                :<>
                                                <label>Comune*</label>
                                                <Typeahead
                                                    disabled={foundUser || !provincia}
                                                    defaultInputValue={citta}
                                                    autocomplete="nope"
                                                    required
                                                    id="basic-typeahead-single2"
                                                    labelKey="nome"
                                                    onChange={impostaComune}
                                                    options={getComuniByProvincia(provincia)}
                                                    placeholder=""
                                                />
                                            </>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>CAP*</label>
                                                    <input required className="form-control" autoComplete="nope" disabled={foundUser} value={codicePostale} onChange={(e)=>setCodicePostale(e.target.value)}  placeholder="Cod Postale"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Indirizzo*</label>
                                                    <input required className="form-control" autoComplete="nope" disabled={foundUser} value={indirizzo} onChange={(e)=>setIndirizzo(e.target.value)}  placeholder="Inserisci indirizzo"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            {paese.toLowerCase() !== "italia" ?
                                                <div className="form-group">
                                                    <label>Codice Fiscale o P.IVA*</label>
                                                    <input required className="form-control" autoComplete="nope"
                                                    disabled={foundUser} value={codiceFiscalePiva} 
                                                    onChange={(e)=>setCodiceFiscalePiva(e.target.value)}  placeholder="Codice Fiscale o P.IVA"/>
                                                </div>
                                            :
                                                <div className="form-group">
                                                    <input type="radio" checked={isCodiceFiscale} name="cfiva" onChange={(e)=>{setIsCodiceFiscale(true);setCodiceFiscalePiva("")}} /> Codice fiscale
                                                    <input style={{marginLeft:"10px"}} type="radio" checked={!isCodiceFiscale} name="cfiva" onChange={(e)=>{setIsCodiceFiscale(false);setCodiceFiscalePiva("")}} /> P.IVA*
                                                    <input required className="form-control" autoComplete="nope"
                                                     disabled={foundUser} value={codiceFiscalePiva} 
                                                     minlength={isCodiceFiscale ? "16":"11"}
                                                     maxlength={isCodiceFiscale ? "16":"11"}
                                                     onChange={(e)=>setCodiceFiscalePiva(e.target.value)}  placeholder={isCodiceFiscale ? "Codice Fiscale":"Partita IVA"}/>
                                                </div>}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>
                                        </div>
                                        {page.corso.term_condition_link || page.corso.responsibility_link ? 
                                            <div className="row">
                                                <div className="col-1">
                                                    {!accepted ? 
                                                        <button className="button-checkbox" style={{borderColor:page.corso.color}}></button>
                                                        :
                                                        <i className="fas fa-check-square" style={{color:page.corso.color}} onClick={()=>setAccepted(false)}></i>}
                                                </div>
                                                <div className="col-11" style={{margin:"0",marginBottom:"15px"}}>
                                                    <label className="form-check-label">
                                                        {page.corso.term_condition_link ? <>Accetto <a href={page.corso.term_condition_link} target="_black">termini e condizioni</a></> : null}
                                                        {page.corso.term_condition_link && page.corso.responsibility_link ? <> e</> : null}
                                                        {page.corso.responsibility_link ? <> ho letto le <a href={page.corso.responsibility_link} target="_black">misure di sicurezza</a></> : null}
                                                    </label>
                                                </div>
                                            </div>
                                        :null}
                                    </form>
                                    </div>
                                </>
                                :<div style={{textAlign:"center"}}>Risulti già iscritto a questo corso</div>
                            :null}
                            {accepted ? 
                                (Number(page.corso.price) - Number(prezzoSconto)).toFixed(0) > 0 ? 
                                <div className="" style={{margin:"0 auto"}}>
                                    {page.corso.paypal_plan_id || page.corso.stripe_plan_id ? 
                                    <section id="tabs">
                                        <nav>
                                            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                                <span className={!isSubscription ? "nav-item nav-link active tabattiva" : "nav-item nav-link"} onClick={()=>setIsSubscription(!isSubscription)}>Unica Rata</span>
                                                <span className={isSubscription ? "nav-item nav-link active tabattiva" : "nav-item nav-link"} onClick={()=>setIsSubscription(!isSubscription)}>Paga in {page.corso.num_rate} rate</span>
                                            </div>
                                        </nav>                                        
                                        <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                            <div className={!isSubscription ? "tab-pane show active" : "tab-pane"} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                {page.corso.show_discount_input ?
                                                    <div className="row" style={{marginTop:"5px"}}>
                                                        <div className="col-12">
                                                            <label><small>hai un codice sconto? verificalo prima di pagare</small></label>
                                                        </div>
                                                        <div className="form-group col-8">
                                                            <input required className="form-control" autoComplete="nope" value={codediscount} onChange={(e)=>setCodediscount(e.target.value)}  placeholder="Codice Sconto"/>
                                                        </div>
                                                        <div className="form-group col-4">
                                                            <button disabled={verificaCodice} className="btn btn-primary" onClick={(e)=>{checkCodeDiscount()}}>Verifica</button>
                                                        </div>
                                                        <div style={{margin: "0 auto"}}>
                                                            {isOkBuono !== null ? 
                                                                isOkBuono === true ? 
                                                                <div style={{color:"green",fontSize:"13px",marginBottom:"15px"}}>Codice sconto valido</div>
                                                                :
                                                                <div style={{color:"red",fontSize:"13px",marginBottom:"15px"}}>Codice sconto non valido</div>
                                                            :null}
                                                        </div>
                                                    </div>
                                                :null}
                                                <div style={{padding:"15px 0"}}>
                                                    {selectMethod === 'stripe' ?
                                                        <CheckoutForm prezzo={(Number(page.corso.price) - Number(prezzoSconto)).toFixed(0)} 
                                                            setMessagePaing={setMessagePaing} 
                                                            email={email} 
                                                            corso_id={page.corso.id} 
                                                            nomecognome={nome+" "+cognome} 
                                                            saveAccount={saveAccount} 
                                                            codicesconto={codediscount}>
                                                        </CheckoutForm>
                                                    :
                                                    <button type="button" className="btn btn-info" style={{width:"100%",fontSize:"16px"}} onClick={()=>{setSelectMethod("stripe")}}>Paga con <b>Stripe</b></button>}
                                                </div>
                                                <div style={{padding:"15px 0"}}>
                                                    {selectMethod === 'paypal' ?
                                                    <>
                                                        <PayPalButton
                                                            amount={(Number(page.corso.price) - Number(prezzoSconto)).toFixed(0)}
                                                            shippingPreference="NO_SHIPPING"
                                                            onSuccess={(details, data) => {
                                                                setMessage("Stiamo elaborando la tua richiesta, attendi un attimo....")
                                                                saveAccount(data.orderID,"succeeded","paypal",false,null)
                                                            }}
                                                            options={{
                                                                clientId: clientPaypal
                                                                ,currency:"EUR"
                                                            }}
                                                        />
                                                    </>
                                                    :<button type="button" className="btn btn-info" style={{width:"100%",fontSize:"16px"}} onClick={()=>{setSelectMethod("paypal")}}>Paga con <b>Paypal</b></button>} 
                                                </div>
                                                {page.corso.bonifico_accepted ? 
                                                    <div style={{padding:"15px 0"}}> 
                                                        {selectMethod === 'bonifico' ?
                                                            <>
                                                                <div>
                                                                    Proseguendo con il pagamento tramite bonifico, sarà creato l'account ma l'accesso al corso potrà avvenire a pagamento ricevuto (circa 2-3 giorni lavorativi)
                                                                </div>
                                                                <div>
                                                                    <small>(i dati per effettuare il bonifico saranno inviati all'indirizzo email <b>{email}</b>)</small>
                                                                </div>
                                                                <div>
                                                                    <button className="button-newsletter-pay" style={{width:"100%",marginTop:"10px"}} onClick={()=>saveAccount("bonifico","succeeded","bonifico",false,null)}>Prosegui</button>
                                                                </div>
                                                            </>
                                                        :
                                                        <div>
                                                            <div style={{padding:"5px 0"}}>
                                                                <button type="button" className="btn btn-info" style={{width:"100%",fontSize:"16px"}} onClick={()=>{setSelectMethod("bonifico")}}>Paga con <b>bonifico</b></button>
                                                            </div>
                                                        </div>
                                                        }  
                                                    </div> 
                                                :null}                              
                                            </div>
                                            <div className={isSubscription ? "tab-pane show active" : "tab-pane"} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            
                                            <div style={{textAlign:"center", padding:"10px"}}>
                                                {page.corso.num_rate} rate x {page.corso.price_rata_sottoscrizione}€ <span style={{fontSize:"18px"}}>({(Number(page.corso.num_rate) * Number(page.corso.price_rata_sottoscrizione)).toFixed(0)}€)</span>
                                            </div>
                                            {page.corso.show_discount_input ?
                                                <div style={{textAlign:"center", fontSize:"12px",paddingBottom:"10px"}} >
                                                    (Non sarà possibile applicare il codice sconto con il pagamento rateizzato)
                                                </div>
                                            :null}

                                            <PayPalButton
                                                options={{vault: true}}
                                                shippingPreference="NO_SHIPPING"
                                                createSubscription={(data, actions) => {
                                                    return actions.subscription.create({
                                                        plan_id: page.corso.paypal_plan_id
                                                    });
                                                }}
                                                onApprove={(data, actions) => {
                                                    // Capture the funds from the transaction
                                                    return actions.subscription.get().then(function(details) {
                                                        // Show a success message to your buyer
                                                        setMessage("Stiamo elaborando la tua richiesta, attendi un attimo....")
                                                        saveAccount(data.orderID,"succeeded","paypal",true,data.subscriptionID)
                                                    });
                                                }}
                                                onError={(data)=>{
                                                    console.log(data);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    </section>
                                    :
                                    <>
                                        <div className={!isSubscription ? "tab-pane show active" : "tab-pane"} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className="row" style={{marginTop:"5px"}}>
                                                <div className="col-12">
                                                    <label><small>hai un codice sconto? verificalo prima di pagare</small></label>
                                                </div>
                                                <div className="form-group col-8">
                                                    <input required className="form-control" autoComplete="nope" value={codediscount} onChange={(e)=>setCodediscount(e.target.value)}  placeholder="Codice Sconto"/>
                                                </div>
                                                <div className="form-group col-4">
                                                    <button disabled={verificaCodice} className="btn btn-primary" onClick={(e)=>{checkCodeDiscount()}}>Verifica</button>
                                                </div>
                                                <div style={{margin: "0 auto"}}>
                                                    {isOkBuono !== null ? 
                                                        isOkBuono === true ? 
                                                        <div style={{color:"green",fontSize:"13px",marginBottom:"15px"}}>Codice sconto valido</div>
                                                        :
                                                        <div style={{color:"red",fontSize:"13px",marginBottom:"15px"}}>Codice sconto non valido</div>
                                                    :null}
                                                </div>
                                            </div>
                                        <div style={{padding:"15px 0"}}> 
                                            {selectMethod === 'stripe' ?
                                                <CheckoutForm prezzo={(Number(page.corso.price) - Number(prezzoSconto)).toFixed(0)} 
                                                    setMessagePaing={setMessagePaing} 
                                                    email={email} 
                                                    corso_id={page.corso.id} 
                                                    nomecognome={nome+" "+cognome} 
                                                    saveAccount={saveAccount} 
                                                    codicesconto={codediscount}>
                                                    </CheckoutForm>
                                                :<button type="button" className="btn btn-info" style={{width:"100%"}} onClick={()=>{setSelectMethod("stripe")}}>Paga con Stripe</button>
                                                }
                                            </div>
                                            <div style={{padding:"15px 0"}}> 
                                                {selectMethod === 'paypal' ?
                                                <>
                                                    <PayPalButton
                                                        amount={(Number(page.corso.price) - Number(prezzoSconto)).toFixed(0)}
                                                        shippingPreference="NO_SHIPPING"
                                                        onSuccess={(details, data) => {
                                                            setMessage("Stiamo elaborando la tua richiesta, attendi un attimo....")
                                                            saveAccount(data.orderID,"succeeded","paypal",false,null)
                                                        }}
                                                        options={{
                                                            clientId: clientPaypal
                                                            ,currency:"EUR"
                                                        }}
                                                    />
                                                </>
                                            :<button type="button" className="btn btn-info" style={{width:"100%"}} onClick={()=>{setSelectMethod("paypal")}}>Paga con Paypal</button>}
                                            </div>
                                            {page.corso.bonifico_accepted ? 
                                                <div style={{padding:"15px 0"}}> 
                                                    {selectMethod === 'bonifico' ?
                                                        <>
                                                            <div>
                                                                Proseguendo con il pagamento tramite bonifico, sarà creato l'account ma l'accesso al corso potrà avvenire a pagamento ricevuto (circa 2-3 giorni lavorativi)
                                                            </div>
                                                            <div>
                                                                <small>(i dati per effettuare il bonifico saranno inviati all'indirizzo email {email})</small>
                                                            </div>
                                                            <div>
                                                                <button className="button-newsletter-pay" style={{width:"100%",marginTop:"10px"}} onClick={()=>saveAccount("bonifico","succeeded","bonifico",false,null)}>Prosegui</button>
                                                            </div>
                                                        </>
                                                    :
                                                    <div>
                                                        <div style={{padding:"5px 0"}}>
                                                            <button type="button" className="btn btn-info" style={{width:"100%",fontSize:"16px"}} onClick={()=>{setSelectMethod("bonifico")}}>Paga con <b>bonifico</b></button>
                                                        </div>
                                                    </div>
                                                    }  
                                                </div>  
                                            :null}                                  
                                        </div>
                                    </>
                                    }
                                </div>
                                :<div>
                                    <button type="button" className="btn btn-info" style={{width:"100%"}} onClick={()=>{saveAccountForFree()}}>Iscriviti gratis</button>
                                </div>
                                :null}
                            <div>
                                {message ?  <div style={{marginTop:"15px", backgroundColor:"#FFD700",textAlign:"center",padding:"5px"}}>{message}</div> : null}
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-9 col-sm-12 col-xs-12 recenzione-div'>
                            <div style={{textAlign:"center"}}>
                                <img style={{width:"50%",paddingBottom:"5px"}} src="https://storage.googleapis.com/blackandwire.appspot.com/image/20221012-172723/202210_ISC_Asset_39.png" alt="" /><br/>
                                Credo fortemente nella potenzialità del corso <b>"{page.corso.title}"</b> e so che ti aiuterà ad acquisire nuove competenze per migliorare le tue creazioni. <br/>
                                Con il programma <b>"soddisfatto o rimborsato"</b> potrai provare il corso e se non è come te lo aspettavi ti basterà scrivermi una mail e verrai <b>rimborsatə al 100% entro 7 giorni</b> dall'acquisto.
                            </div>
                            {page.corso.recenzioni_json ? 
                                <>
                                <div style={{borderTop:"1px solid grey",textAlign:"center",fontSize:"14px",fontWeight:"bold",paddingTop:"10px",marginTop:"10px"}}>DICONO DEL CORSO</div>
                                    {JSON.parse(page.corso.recenzioni_json).map((recenzione ,i)=>{
                                        return(
                                            <div className='row' key={i} style={{marginTop:"20px"}}>
                                                <span className='col-12'>
                                                    {/* <img src={recenzione.image} style={{width:"100%",maxWidth:"50px"}} alt="" className='recenzione-img' /> */}
                                                    <div style={{textAlign:'left',width:"90%",color:"#053861"}}><b><i>{recenzione.name}</i></b></div>
                                                    <span dangerouslySetInnerHTML={{ __html: recenzione.recenzione}}/></span>
                                                    <hr />
                                                    
                                            </div>
                                        )
                                    })}
                                </>
                                : null }
                                </div>
                               
                        </div>
                    </div>
                </Elements>
            </div>
            :
            <>
                {/* <div className="container" style={{color:page.corso.color}}>
                    <h1 className={"corso-menu-titolo"}>{page.corso.title}</h1>
                </div>
                <div className="container">
                    <div className="col-lg-7 col-md-9 col-sm-12 col-xs-12" style={{margin:"0 auto"}} dangerouslySetInnerHTML={{ __html: page.thankyou_page}}/>
                    {ReactPixel.track('Purchase', "{value:"+page.corso.price+", currency: 'EUR'")}
                </div> */}
                <Redirect to={"/corsi/"+props.match.params.slugcorso+"/complete-payment"} />
            </>
            :null}
        </div>
    );
}

export default PaymentPage;

// inserire numero di cicli su corso 
// inserire periodo di fatturazione //giorno//settimana//mese//anno , default mese
