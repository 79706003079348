import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import Moments from '../items/Moments'

export function BlogPosts(props) {
    const [post, setPost] = useState(undefined)
    useEffect(() => {
        setPost(props.post)
        return () => {
        };
    }, [props.post])
    return(
        <>
            {post !== undefined ? 
                <div className="blog-box">
                    <Link to={"/blog/"+post.gurl} style={{textAlign:"left",textDecoration:"none" }}>
                        <div className="immagineblog" style={{paddingTop:"10px", width:"100%", height:"200px", backgroundImage: "url(" + (post.file ? post.file.split("?")[0] : "") + ")",
                        backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', padding:"0"}}>
                        </div>
                        <div className="titleblog"><h3>{post.title.toUpperCase()}</h3></div>
                    </Link> 
                    <div className="descriptionblog"><p style={{textOverflow:"ellipsis",textAlign:"left"}}>{post.gdescription}</p></div> 
                    <div style={{textAlign:"left",fontSize:"14px",position:"absolute",bottom:0}}>
                        <small>Posted <b><Moments data={post.published_at}></Moments></b> ago</small>
                    </div>
                </div>
            :null}
        </>
    )
}